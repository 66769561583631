import React, { useCallback, useRef, useState } from 'react';

import cn from 'classnames';

import filterMobileIcon from '../../../assets/icons/filter.svg';
import filterMobileActiveIcon from '../../../assets/icons/filter-active.svg';
import filterDesktopIcon from '../../../assets/icons/arrow-expand.svg';

import useCheckDesktopScreen from '../../../hooks/useCheckDesktopScreen';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import Checkbox from '../../UI/Checkbox';
import classes from './styles.module.scss';

const Filter = ({ options, handleFilterOptionsChange }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const isDesktop = useCheckDesktopScreen();

  const filterButtonRef = useRef();
  const filterMenuRef = useRef();

  useOnClickOutside(
    filterMenuRef,
    () => setIsFilterOpen(false),
    filterButtonRef
  );

  const handleFilterToggle = useCallback(() => {
    setIsFilterOpen((prevState) => !prevState);
  }, []);

  let filterIcon;

  if (isDesktop) {
    filterIcon = filterDesktopIcon;
  } else if (isFilterOpen) {
    filterIcon = filterMobileActiveIcon;
  } else {
    filterIcon = filterMobileIcon;
  }

  return (
    <div
      className={cn(classes.Filter, {
        [classes.open]: isFilterOpen,
      })}
    >
      <RoundButtonWithIcon
        onClick={handleFilterToggle}
        reference={filterButtonRef}
        icon={filterIcon}
        iconAlt="Filter"
        classnames={[classes.filterButton]}
      />
      <div ref={filterMenuRef} className={classes.filterOptionsContainer}>
        <div className={classes.checkboxes}>
          {options.map((option) => (
            <Checkbox
              key={option.value}
              label={option.label}
              value={option.value}
              onClick={(e) => handleFilterOptionsChange(e, option.filterKey)}
              labelClass={classes.label}
              color={!isDesktop && 'white'}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Filter;
