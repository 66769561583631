import React from 'react';

import cn from 'classnames';

import editIcon from '../../../../assets/icons/pen-active.svg';

import useUser from '../../../../hooks/useUser';
import Avatar from '../../../UI/Avatar';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const Customer = ({
  customer,
  isEngagementOpen,
  isServiceProviderView,
  onEditClick,
}) => {
  const { isAdmin, isProjectManager } = useUser();

  const isEditVisible = (isAdmin || isProjectManager) && isEngagementOpen;

  return (
    <div
      className={cn(classes.Customer, {
        [classes.open]: isEngagementOpen,
        [classes.serviceProvider]: isServiceProviderView,
        [classes.noChargeExists]: !customer.hasChargeMethod,
      })}
    >
      <span className={classes.title}>Customer:</span>
      <div className={classes.infoContainer}>
        <Avatar
          imagePath={customer.avatarPath}
          alt={customer.name}
          className={cn({
            [classes.avatar]: !isServiceProviderView,
            [classes.avatarSpView]: isServiceProviderView,
          })}
        />
        <div className={classes.infoInnerContainer}>
          <span className={classes.name}>
            {customer.name}
            {isEditVisible && (
              <RoundButtonWithIcon
                onClick={onEditClick}
                icon={editIcon}
                iconAlt="Edit Customer"
                iconSize={8}
                classnames={[classes.icon]}
              />
            )}
          </span>

          {isServiceProviderView && (
            <span className={classes.email}>{customer.email}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Customer;
