import React, { useState, useContext, useCallback } from 'react';

import { useMutation } from 'react-query';
import PublicProfileLinkService from '../../../services/PublicProfileLinkService';
import { UiContext } from '../../../context/UiContext';
import Input from '../../UI/Input';
import ErrorMessage from '../../UI/ErrorMessage';
import PrimaryButton from '../../UI/Buttons/PrimaryButton';
import PublicProfileLinkList from './PublicProfileLinkList';
import HoursCounterModal from '../../../modals/HoursCounterModal';
import classes from './styles.module.scss';

const ServiceProviderDetails = ({
  userId,
  links: linkItems,
  rate,
  onRateChange,
  onRateBlur,
  isRateTouched,
  rateErrors,
  onEditorChange,
  onPublicProfileClick,
  isCustomLinkDisabled,
  stripeCountryDropdown,
}) => {
  const [shouldHoursCounterModalOpen, setShouldHoursCounterModalOpen] =
    useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [links, setLinks] = useState(linkItems ? [...linkItems] : null);
  const { showDeleteModal, showErrorModal } = useContext(UiContext);

  const { mutate: createProfileLinkMutation } = useMutation(
    PublicProfileLinkService.createLink,
    {
      onSuccess: (data) => {
        setLinks((oldLinks) => {
          const newLinks = [...oldLinks];
          newLinks.unshift(data.link);
          return newLinks;
        });
        setShouldHoursCounterModalOpen(false);
      },
      onError: (error) => {
        showErrorModal({
          message: error.response.data.message || error.response.data.error,
        });
      },
    }
  );

  const { mutate: deleteProfileLinkMutation } = useMutation(
    PublicProfileLinkService.deleteLink,
    {
      onSuccess: (data, linkId) => {
        setLinks((oldLinks) => oldLinks.filter((x) => x.id !== linkId));
      },
      onError: (error) => {
        showErrorModal({
          message: error.response.data.message || error.response.data.error,
        });
      },
    }
  );

  const handleCreateLink = useCallback(
    (calculatedHours) => {
      createProfileLinkMutation({
        userId,
        hours: calculatedHours,
      });
    },
    [userId, createProfileLinkMutation]
  );

  const handleLinkDelete = useCallback(
    (id) => {
      showDeleteModal({
        data: id,
        handleDelete: () => deleteProfileLinkMutation(id),
        type: 'profile link',
      });
    },
    [showDeleteModal, deleteProfileLinkMutation]
  );

  return (
    <div className={classes.ServiceProviderDetails}>
      <div className={classes.inputContainer}>
        <div className={classes.rateContainer}>
          <Input
            value={rate}
            name="rate"
            type="number"
            onChange={onRateChange}
            onBlur={onRateBlur}
            classnames={[classes.input, classes.rate]}
            placeholder="Service Provider Standard Billable Rate"
            hasError={isRateTouched && rateErrors}
          />
        </div>
        {isRateTouched && rateErrors && <ErrorMessage message={rateErrors} />}
      </div>
      {stripeCountryDropdown}
      <div className={classes.buttonsContainer}>
        <div className={classes.left}>
          <PrimaryButton
            disabled={!linkItems}
            onClick={() => setShouldHoursCounterModalOpen(true)}
            classnames={[
              classes.profileButtons,
              classes.createProfileLinkButton,
            ]}
          >
            Create Profile Link
          </PrimaryButton>
        </div>
        <div className={classes.right}>
          <PrimaryButton
            disabled={!linkItems}
            onClick={onEditorChange}
            iconName="arrow"
            classnames={[classes.profileButtons, classes.updateProfileButton]}
          >
            Update Profile
          </PrimaryButton>
          <PrimaryButton
            disabled={isCustomLinkDisabled || !linkItems}
            onClick={onPublicProfileClick}
            iconName="arrow"
            classnames={[classes.profileButtons, classes.previewProfileButton]}
          >
            Preview Profile
          </PrimaryButton>
        </div>
      </div>
      <div className={classes.profileLinksContainer}>
        <PublicProfileLinkList
          links={links || []}
          handleLinkDelete={handleLinkDelete}
        />
      </div>
      <HoursCounterModal
        negativeTimeAllowed={false}
        title="Create new link"
        description="New link will be valid from its creation date to specified hours and minutes from following counters."
        hours={hours}
        minutes={minutes}
        setHours={setHours}
        setMinutes={setMinutes}
        submitButtonText="Create"
        onSubmit={handleCreateLink}
        show={shouldHoursCounterModalOpen}
        handleClose={() => setShouldHoursCounterModalOpen(false)}
      />
    </div>
  );
};

export default ServiceProviderDetails;
