import axios, { axiosPrivate } from '../api/axios';

export async function login({
  emailOrPhone,
  passwordOrCode,
  roles,
  rememberme,
}) {
  try {
    const response = await axios.post(`/login`, {
      email_or_phone: emailOrPhone,
      password_or_code: passwordOrCode,
      roles: JSON.stringify(roles),
      rememberme,
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
}

export async function resetPassword({ email }) {
  try {
    await axios.post(`/forgot-password`, {
      email,
    });
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
}

export async function createPassword({ password, token }) {
  try {
    await axios.post(`/create-password`, {
      password,
      token,
    });
  } catch (error) {
    console.log(error);
    throw new Error(error.response.data.message);
  }
}

export async function logout() {
  try {
    await axiosPrivate.post('/logout');
    return true;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
}
