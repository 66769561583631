/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

const CountryOption = ({ item, handleItemClick, isSelected }) => {
  const handleItemSelect = useCallback(() => {
    if (!isSelected) {
      handleItemClick(item);
    }
  }, [item, isSelected, handleItemClick]);

  return (
    <li
      onClick={handleItemSelect}
      key={item.id}
      className={cn(classes.CountryOption, {
        [classes.selected]: isSelected,
      })}
    >
      <div className={classes.infoContainer}>
        <span className={classes.name}>{item.name}</span>
      </div>
    </li>
  );
};

export default CountryOption;
