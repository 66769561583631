import React from 'react';

import classes from './styles.module.scss';
import RoundButtonWithIcon from '../../../../UI/Buttons/RoundButtonWithIcon';
import arrowBlue from '../../../../../assets/icons/arrow-blue.svg';

const NumberCounter = ({ label, value, onClick }) => {
  return (
    <div className={classes.NumberCounter}>
      <RoundButtonWithIcon
        onClick={() => onClick(true)}
        icon={arrowBlue}
        iconAlt="Increase"
        classnames={[classes.arrow, classes.upArrow]}
      />
      <div className={classes.counterBox}>{value}</div>
      <div className={classes.counterLabel}>{label}</div>
      <RoundButtonWithIcon
        onClick={() => onClick(false)}
        icon={arrowBlue}
        iconAlt="Decrease"
        classnames={[classes.arrow, classes.downArrow]}
      />
    </div>
  );
};

export default NumberCounter;
