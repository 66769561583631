import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Inbox } from '@talkjs/react';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import classes from './styles.module.scss';
import useUser from '../../hooks/useUser';
import ChatService from '../../services/ChatService';

const ChatPage = () => {
  const { conversationId } = useParams();
  const { currentUser } = useUser();

  const { data, isLoading } = useQuery(
    ['archived-conversations'],
    () => ChatService.getArchivedConversations(),
    {
      keepPreviousData: true,
    }
  );

  return (
    <div className={classes.ChatPage}>
      <HeaderMenuContainer contentClassname={classes.mainContent}>
        <div className={classes.chatContainer}>
          {currentUser.chatUserId && !isLoading ? (
            <Inbox
              className={classes.inbox}
              {...(!!conversationId && {
                conversationId,
              })}
              theme={{
                custom: {
                  archived: data.archivedConversations,
                },
              }}
            />
          ) : (
            <div className={classes.noConversation}>
              <p>
                {isLoading
                  ? 'Loading'
                  : 'You are not part of any conversations.'}
              </p>
            </div>
          )}
        </div>
      </HeaderMenuContainer>
    </div>
  );
};

export default ChatPage;
