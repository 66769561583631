import React from 'react';

import clockIcon from '../../../../../../assets/icons/clock.svg';

import classes from './styles.module.scss';

const TotalOrLoggedHours = ({ hours, isTotal }) => {
  const hrs = Math.floor(hours);
  const mins = Math.round((hours - hrs) * 60);
  return (
    <div className={classes.TotalOrLoggedHours}>
      <span className={classes.title}>
        {isTotal ? 'Total hours' : 'Hours this week'}:
      </span>
      <span className={classes.content}>
        <img src={clockIcon} alt="Hours" /> {`${hrs}h ${mins}m`}
      </span>
    </div>
  );
};

export default TotalOrLoggedHours;
