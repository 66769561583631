import React from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import cn from 'classnames';

import classes from './styles.module.scss';

const PhoneNumberInput = ({
  hasError,
  classnames = [],
  reference,
  country,
  ...rest
}) => {
  return (
    <PhoneInput
      defaultCountry={country || 'US'}
      initialValueFormat="national"
      className={cn(
        classes.PhoneNumberInput,
        {
          [classes.error]: hasError,
          [classes.disabled]: rest.disabled,
        },
        ...classnames
      )}
      ref={reference}
      {...rest}
    />
  );
};

export default PhoneNumberInput;
