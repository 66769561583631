import React, { useContext, useEffect, useState } from 'react';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  AddressElement,
} from '@stripe/react-stripe-js';
import cn from 'classnames';

import addCardIcon from '../../../assets/icons/add-card.svg';
import cardIcon from '../../../assets/icons/card.svg';
import whiteCardIcon from '../../../assets/icons/card-white.svg';

import PrimaryButton from '../../UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';
import { UiContext } from '../../../context/UiContext';

const CARD_STATUS = {
  EMPTY: 'empty',
  FILLING: 'filling',
  FILLED: 'filled',
  LOADING: 'loading',
};

const renderDotsGroup = (num = 4) => (
  <div key={Math.random()} className={classes.dotsGroup}>
    {[...Array(num)].map(() => (
      <span key={Math.random()} className={classes.dot} />
    ))}
  </div>
);

const renderCardExpiry = (month, year) => {
  return `${month.toString().length === 1 ? '0' : ''}${month} / ${year}`;
};

const Card = ({ cardDetails, className, onSubmit, onUpdate }) => {
  const [cardStatus, setCardStatus] = useState(CARD_STATUS.EMPTY);
  const { showErrorModal } = useContext(UiContext);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (cardDetails) {
      setCardStatus(CARD_STATUS.FILLED);
    }
  }, [cardDetails]);

  const createOrUpdateCard = async (onSucceed) => {
    const cardNumberElement = elements.getElement('cardNumber');
    if (!cardNumberElement) {
      setCardStatus(CARD_STATUS.FILLING);
      return;
    }

    const cardAddressDetails = await elements.getElement('address')?.getValue();

    if (!cardAddressDetails || !cardAddressDetails.complete) {
      setCardStatus(CARD_STATUS.FILLING);
      return;
    }

    try {
      const { token } = await stripe.createToken(cardNumberElement, {
        ...cardAddressDetails.value,
      });
      if (!token) {
        throw new Error('Incorect input. Please, try again.');
      }

      setCardStatus(CARD_STATUS.LOADING);
      await onSucceed(token.id);
      setCardStatus(CARD_STATUS.FILLED);
    } catch (error) {
      console.log(error);
      showErrorModal({
        message: error.message,
      });
    }
  };

  const handleSubmit = () => {
    createOrUpdateCard(onSubmit);
  };

  const handleUpdate = () => {
    createOrUpdateCard(onUpdate);
  };

  const cancelUpdate = async () => {
    setCardStatus(CARD_STATUS.FILLED);
  };

  let content;

  switch (cardStatus) {
    case CARD_STATUS.EMPTY:
      content = (
        <div
          onClick={() => setCardStatus(CARD_STATUS.FILLING)}
          className={classes.empty}
          type="button"
        >
          <img src={addCardIcon} alt="Add card" />
          <span>Add card</span>
        </div>
      );
      break;

    case CARD_STATUS.FILLING:
      content = (
        <div className={classes.filling}>
          <div className={classes.top}>
            <CardNumberElement className={classes.cardNumberElement} />
            <img src={cardIcon} alt="Card" />
          </div>
          <div className={classes.bottom}>
            <CardExpiryElement className={classes.cvcAndExpiresElement} />
            <CardCvcElement className={classes.cvcAndExpiresElement} />
          </div>
        </div>
      );
      break;

    case CARD_STATUS.FILLED:
      content = (
        <div className={classes.filled}>
          <h1 className={classes.cardHolderName}>{cardDetails?.name}</h1>
          <div className={classes.cardNumber}>
            {[...Array(3)].map(() => renderDotsGroup())}
            {cardDetails?.last4}
            <img src={whiteCardIcon} alt="Card" />
          </div>
          {!!cardDetails?.exp_month && !!cardDetails?.exp_year && (
            <div className={classes.cardDetailBottomContainer}>
              <div className={classes.cardDetailInfo}>
                <div className={classes.label}>CVC:</div>
                <div className={classes.label}>{renderDotsGroup(3)}</div>
              </div>
              <div className={classes.cardDetailInfo}>
                <div className={classes.label}>EXP:</div>
                <div className={classes.label}>
                  {renderCardExpiry(
                    cardDetails?.exp_month,
                    cardDetails?.exp_year
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      );
      break;

    case CARD_STATUS.LOADING:
      content = (
        <div className={classes.loading}>
          <span>Loading...</span>
        </div>
      );
      break;

    default:
      break;
  }

  return (
    <div className={cn(classes.Card, className)}>
      <div
        className={cn(classes.innerCard, {
          [classes.filled]: cardStatus === CARD_STATUS.FILLED,
        })}
      >
        {content}
      </div>
      {cardStatus === CARD_STATUS.FILLING && (
        <div className={classes.address}>
          <h1>Billing Details</h1>
          <AddressElement
            options={{
              mode: 'billing',
              blockPoBox: true,
              fields: {
                phone: 'always',
              },
              validation: {
                phone: {
                  required: 'never',
                },
              },
              defaultValues: { ...(!!cardDetails && cardDetails) },
            }}
          />
        </div>
      )}
      <div className={classes.updateButtonContainer}>
        {cardStatus === CARD_STATUS.FILLING && !cardDetails && (
          <PrimaryButton
            onClick={handleSubmit}
            classnames={[classes.button]}
            iconName="checkmark"
          >
            Add card
          </PrimaryButton>
        )}
        {cardDetails && (
          <>
            <PrimaryButton
              onClick={handleUpdate}
              classnames={[classes.button]}
              iconName="checkmark"
            >
              {cardStatus === CARD_STATUS.FILLING ? 'Update' : 'Edit'} card
            </PrimaryButton>
            {cardStatus === CARD_STATUS.FILLING && (
              <PrimaryButton
                onClick={cancelUpdate}
                variant="outline"
                classnames={[classes.button, classes.cancelButton]}
              >
                Cancel
              </PrimaryButton>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
