/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useRef, useState } from 'react';

import cn from 'classnames';

import useOnClickOutside from '../../../../hooks/useOnClickOutside';
import DropdownList from '../../../UI/Dropdown/DropdownList';
import DropdownItem from './CountryOption';
import Search from '../../../UI/Dropdown/Search';
import classes from './styles.module.scss';

const StripeCountryDropdown = ({
  items,
  selectedItem,
  label,
  onItemClick,
  onTouched,
  hasError,
  searchValue,
  onSearch,
  clearSearchValue,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    if (isDropdownOpen && onTouched) {
      onTouched();
    }

    setIsDropdownOpen(false);
  });

  const handleDropdownToggle = useCallback(() => {
    if (isDropdownOpen && onTouched) {
      onTouched();
    }

    if (!isDropdownOpen) {
      setTimeout(() => searchInputRef.current.focus());
    }

    if (!isDropdownOpen) {
      clearSearchValue();
    }

    setIsDropdownOpen((prevState) => !prevState);
  }, [isDropdownOpen, onTouched, clearSearchValue]);

  const handleItemClick = useCallback(
    (item) => {
      onItemClick(item);
      setIsDropdownOpen(false);
    },
    [onItemClick]
  );

  let dropdownButtonContent = <span>{label}</span>;

  if (selectedItem) {
    dropdownButtonContent = <DropdownItem item={selectedItem} isSelected />;
  }

  if (isDropdownOpen) {
    dropdownButtonContent = (
      <Search
        value={searchValue}
        onSearch={onSearch}
        searchInputRef={searchInputRef}
      />
    );
  }

  return (
    <div
      ref={dropdownRef}
      className={cn(classes.StripeCountryDropdown, {
        [classes.open]: isDropdownOpen,
        [classes.selected]: selectedItem && !isDropdownOpen,
        [classes.error]: hasError,
      })}
    >
      <div onClick={handleDropdownToggle} className={classes.dropdownToggle}>
        {dropdownButtonContent}
      </div>
      <DropdownList
        items={items}
        dropdownItem={DropdownItem}
        handleItemClick={handleItemClick}
        isDropdownOpen={isDropdownOpen}
      />
    </div>
  );
};

export default StripeCountryDropdown;
