import React, { useCallback } from 'react';

import cn from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ModalContainer from '../ModalContainer';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import ErrorMessage from '../../components/UI/ErrorMessage';
import Input from '../../components/UI/Input';
import classes from './styles.module.scss';

const UpdateDirtyLogModal = ({
  show,
  logId,
  isGettingPaid,
  handleClose,
  onSubmit,
}) => {
  const validationSchema = yup.object({
    stripeTransactionId: yup
      .string()
      .trim()
      .required(`${isGettingPaid ? 'Transfer' : 'Charge'} id is required.`),
  });

  const handleSubmit = useCallback(
    ({ id, stripeTransactionId }) => {
      onSubmit({
        id,
        stripeTransactionId,
      });
      handleClose();
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: {
      id: logId,
      stripeTransactionId: '',
    },
    validationSchema,
    onSubmit: (e) => {
      handleSubmit(e);
      formik.resetForm();
    },
    enableReinitialize: true,
  });

  return (
    <ModalContainer
      show={show}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
      contentClassName={classes.DirtyPaymentLogModal}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h2 className={cn(classes.section, classes.title)}>
          Update payment log
        </h2>

        <div className={cn(classes.section, classes.body)}>
          <form className={classes.form}>
            <div className={classes.formContainer}>
              <div className={classes.inputs}>
                <div className={classes.inputContainer}>
                  <Input
                    value={formik.values.stripeTransactionId}
                    name="stripeTransactionId"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    classnames={[classes.input]}
                    placeholder={isGettingPaid ? 'Transfer Id' : 'Charge Id'}
                    hasError={
                      formik.touched.stripeTransactionId &&
                      formik.errors.stripeTransactionId
                    }
                  />
                  {formik.touched.stripeTransactionId &&
                    formik.errors.stripeTransactionId && (
                      <ErrorMessage
                        message={formik.errors.stripeTransactionId}
                      />
                    )}
                </div>
              </div>
            </div>
          </form>
          <div className={classes.buttons}>
            <PrimaryButton
              classnames={[classes.submitButton]}
              onClick={formik.handleSubmit}
            >
              Submit
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default UpdateDirtyLogModal;
