import React, { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ModalContainer from '../ModalContainer';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import ErrorMessage from '../../components/UI/ErrorMessage';
import Input from '../../components/UI/Input';
import classes from './styles.module.scss';
import validateFileSize from '../../helpers/validateFileSize';
import AvatarUpload from '../../components/UI/AvatarUpload';
import GenericDropdown from '../../components/UI/GenericDropdown';

const CreateOrUpdateConversationModal = ({
  show,
  engagementData,
  handleClose,
  onSubmit,
  showErrorModal,
}) => {
  const { conversationData } = engagementData;
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState('');
  const initialSelectedUsers = conversationData
    ? conversationData.members.map((x) => x.id)
    : [];

  useEffect(() => {
    if (conversationData) {
      setAvatarPreview(conversationData.avatar);
    }
  }, [conversationData]);

  const users = engagementData.id
    ? [
        {
          id: engagementData.pm.id,
          label: engagementData.pm.name,
          avatarPath: engagementData.pm.avatarPath,
        },
        {
          id: engagementData.customer.id,
          label: engagementData.customer.name,
          avatarPath: engagementData.customer.avatarPath,
        },
        ...(engagementData.hires || []).map((x) => ({
          id: x.id,
          label: x.name,
          avatarPath: x.avatarPath,
        })),
      ]
    : [];

  const validationSchema = yup.object({
    conversationTitle: yup
      .string()
      .trim()
      .required(`Conversation title is required.`),
    conversationDescription: yup.string().trim(),
    userIds: yup
      .array()
      .of(yup.string())
      .min(2, 'You must select 2 users to create conversation.'),
  });

  const handleAvatarUploadChange = useCallback((event) => {
    const file = event.target.files[0];

    if (file) {
      if (!validateFileSize(file.size, 5)) {
        showErrorModal({
          message: 'Max file upload size is 5mb.',
        });
        return;
      }

      setAvatarFile(file);

      const reader = new FileReader();

      reader.onload = (e) => {
        setAvatarPreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      conversationTitle: conversationData ? conversationData.title : '',
      conversationDescription: conversationData
        ? conversationData.description
        : '',
      userIds: initialSelectedUsers,
    },
    validationSchema,
    onSubmit: (data) => {
      onSubmit({
        ...data,
        engagementId: engagementData.id,
        groupAvatar: avatarFile,
        ...(!!conversationData && {
          conversationId: conversationData.id,
        }),
      });
      setAvatarFile(null);
      setAvatarPreview('');
      handleClose();
      formik.resetForm();
    },
    enableReinitialize: true,
  });

  const handleItemClick = (item) => {
    if (!item) return;

    if (formik.values.userIds.includes(item.id)) {
      formik.setFieldValue(
        'userIds',
        formik.values.userIds.filter((x) => x !== item.id)
      );
      return;
    }

    formik.setFieldValue('userIds', [...formik.values.userIds, item.id]);

    formik.setFieldTouched('userIds', false, false);
  };

  return (
    <ModalContainer
      show={show}
      handleClose={() => {
        handleClose();
        formik.resetForm();
      }}
      contentClassName={classes.CreateConversationModal}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h2 className={cn(classes.section, classes.title)}>
          {conversationData ? 'Update' : 'Create New'} Conversation
        </h2>

        <div className={cn(classes.section, classes.body)}>
          <div className={classes.avatarContainer}>
            <AvatarUpload
              avatarPreview={avatarPreview}
              handleAvatarUploadChange={handleAvatarUploadChange}
            />
          </div>
          <form className={classes.form}>
            <div className={classes.formContainer}>
              <div className={classes.inputs}>
                <div className={classes.inputContainer}>
                  <Input
                    value={formik.values.conversationTitle}
                    name="conversationTitle"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    classnames={[classes.input]}
                    placeholder="Conversation title"
                    hasError={
                      formik.touched.conversationTitle &&
                      formik.errors.conversationTitle
                    }
                  />
                  {formik.touched.conversationTitle &&
                    formik.errors.conversationTitle && (
                      <ErrorMessage message={formik.errors.conversationTitle} />
                    )}
                </div>
                <div className={classes.inputContainer}>
                  <Input
                    value={formik.values.conversationDescription}
                    name="conversationDescription"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    classnames={[classes.input]}
                    placeholder="Conversation description (optional)"
                  />
                </div>
                <div className={classes.inputContainer}>
                  <GenericDropdown
                    multiSelect
                    showAvatar
                    label="Select users for conversation..."
                    items={users}
                    selectedItems={users.filter((x) =>
                      formik.values.userIds.includes(x.id)
                    )}
                    onItemClick={handleItemClick}
                    classnames={[classes.dropdownContainer]}
                  />
                  {formik.touched.userIds && formik.errors.userIds && (
                    <ErrorMessage message={formik.errors.userIds} />
                  )}
                </div>
              </div>
            </div>
          </form>
          <div className={classes.buttons}>
            <PrimaryButton
              classnames={[classes.submitButton]}
              onClick={formik.handleSubmit}
            >
              {conversationData ? 'Update' : 'Create'}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CreateOrUpdateConversationModal;
