import React from 'react';
import cn from 'classnames';

import Checkbox from '../../UI/Checkbox';
import classes from './styles.module.scss';

const ArchiveCheckbox = ({ archived, handleArchiveChange }) => {
  return (
    <div
      className={cn(classes.ArchiveCheckbox, {
        [classes.archived]: archived,
      })}
    >
      <Checkbox
        name="archive"
        onClick={handleArchiveChange}
        label="Mark User Archived"
        value={archived}
        isChecked={archived}
      />
    </div>
  );
};

export default ArchiveCheckbox;
