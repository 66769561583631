/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import moment from 'moment';
import trashIcon from '../../../../../assets/icons/trash-blue.svg';
import RoundButtonWithIcon from '../../../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const PublicProfileLinkItem = ({ data, handleLinkDelete }) => {
  const { id, link, expiresAt } = data || {};
  const localDateTime = moment(new Date(expiresAt)).format(
    'ddd, MMM Do YYYY, h:mm a'
  );

  return (
    <li className={classes.PublicProfileLinkItem}>
      <div title={link} className={classes.profileInfoContainer}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          {link}
        </a>
        <span className={classes.expiresAt}>Valid till {localDateTime}</span>
      </div>

      <RoundButtonWithIcon
        onClick={() => handleLinkDelete(id)}
        classnames={[classes.deleteButton]}
        icon={trashIcon}
      />
    </li>
  );
};

export default PublicProfileLinkItem;
