import React from 'react';
import Cookies from 'js-cookie';

import { useQuery } from 'react-query';
import { Redirect, Route } from 'react-router-dom';

import UserService from '../../services/UserService';

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const { data: currentUser, isLoading: isUserLoading } = useQuery(
    'currentUser',
    UserService.getMe,
    {
      staleTime: Infinity,
      retry: 0,
    }
  );

  const hasRequiredRole = roles.some((role) =>
    currentUser?.roles?.includes(role)
  );

  if (isUserLoading) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        hasRequiredRole ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: Cookies.get('login-path') || '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
