import React, { useCallback, useMemo, useEffect, useRef } from 'react';

import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';

import { CiViewList, CiBank } from 'react-icons/ci';
import { PiChatCircleDotsThin } from 'react-icons/pi';
import logo from '../../assets/images/logo.svg';
import inactiveEngagementsIcon from '../../assets/icons/engagements.svg';
import inactiveBillingIcon from '../../assets/icons/billing.svg';
import inactiveSettingsIcon from '../../assets/icons/settings.svg';
import inactiveReferralsIcon from '../../assets/icons/referrals.svg';
import inactiveUsersManagementIcon from '../../assets/icons/letter.svg';
import activeEngagementsIcon from '../../assets/icons/engagements-active.svg';
import activeBillingIcon from '../../assets/icons/billing-active.svg';
import activeSettingsIcon from '../../assets/icons/settings-active.svg';
import activeReferralsIcon from '../../assets/icons/referrals-active.svg';
import activeUsersManagementIcon from '../../assets/icons/letter-active.svg';
import gettingStartedIcon from '../../assets/icons/pen.svg';
import gettingStartedActiveIcon from '../../assets/icons/pen-active.svg';

import PrimaryButton from '../UI/Buttons/PrimaryButton';
import useUser from '../../hooks/useUser';
import useCheckDesktopScreen from '../../hooks/useCheckDesktopScreen';
import useLogout from '../../hooks/useLogout';
import classes from './styles.module.scss';

const options = [
  {
    label: '',
    to: '',
    inactiveIcon: null,
    activeIcon: null,
  },
  {
    label: 'Getting Started',
    to: '/getting-started',
    inactiveIcon: <img src={gettingStartedIcon} alt="Getting started" />,
    activeIcon: <img src={gettingStartedActiveIcon} alt="Getting started" />,
  },
  {
    label: 'Messaging',
    to: '/chat',
    inactiveIcon: <PiChatCircleDotsThin color="#000" />,
    activeIcon: <PiChatCircleDotsThin color="#000" />,
  },
  {
    label: 'Engagements',
    to: '/engagements',
    inactiveIcon: <img src={inactiveEngagementsIcon} alt="Engagements" />,
    activeIcon: <img src={activeEngagementsIcon} alt="Engagements" />,
  },
  {
    label: 'Billing & Payments',
    to: '/billing-payments',
    inactiveIcon: <img src={inactiveBillingIcon} alt="Billing & Payments" />,
    activeIcon: <img src={activeBillingIcon} alt="Billing & Payments" />,
  },
  {
    label: 'Payment Logs',
    to: '/dirty-payment-logs',
    inactiveIcon: <img src={inactiveBillingIcon} alt="Payment Logs" />,
    activeIcon: <img src={activeBillingIcon} alt="Payment Logs" />,
  },
  {
    label: 'Account & Settings',
    to: '/account-settings',
    inactiveIcon: <img src={inactiveSettingsIcon} alt="Account & Settings" />,
    activeIcon: <img src={activeSettingsIcon} alt="Account & Settings" />,
  },
  {
    label: 'Referrals',
    to: '/referrals',
    inactiveIcon: <img src={inactiveReferralsIcon} alt="Referrals" />,
    activeIcon: <img src={activeReferralsIcon} alt="Referrals" />,
  },
  {
    label: 'Users Management',
    to: '/users-management',
    // eslint-disable-next-line prettier/prettier
    inactiveIcon: (
      <img src={inactiveUsersManagementIcon} alt="Users Management" />
    ),
    activeIcon: <img src={activeUsersManagementIcon} alt="Users Management" />,
  },
  {
    label: 'Stripe Users',
    to: '/stripe-users',
    inactiveIcon: <CiBank color="#000" />,
    activeIcon: <CiBank color="#000" />,
  },
  {
    label: 'Service Provider Profile',
    to: '/service-provider-profile',
    // eslint-disable-next-line prettier/prettier
    inactiveIcon: (
      <img src={inactiveSettingsIcon} alt="Service Provider Profile" />
    ),
    activeIcon: <img src={activeSettingsIcon} alt="Service Provider Profile" />,
  },
  {
    label: 'Service Provider Report',
    to: '/service-provider-report',
    // eslint-disable-next-line prettier/prettier
    inactiveIcon: <CiViewList color="#000" />,
    activeIcon: <CiViewList color="#000" />,
  },
  {
    label: '',
    to: '',
    inactiveIcon: null,
    activeIcon: null,
  },
];

const Menu = ({ handleMenuClose, isOpen, unreadMessageCount }) => {
  const handleLogout = useLogout();
  const menuRef = useRef(null);

  const { pathname } = useLocation();
  const isDesktop = useCheckDesktopScreen();
  const {
    currentUser,
    isAdmin,
    isProjectManager,
    isCustomer,
    isServiceProvider,
  } = useUser();

  useEffect(() => {
    if (!menuRef.current || !isDesktop) {
      return;
    }

    const { right } = menuRef.current.getBoundingClientRect();
    const menuRight = window.innerWidth - right;

    menuRef.current?.style.setProperty('--right', `${menuRight}px`);
  }, [menuRef, window.innerWidth]);

  const handleLinkClick = useCallback(() => {
    if (!isDesktop) {
      handleMenuClose();
    }
  }, []);

  const filteredOptions = useMemo(() => {
    return options.filter((option) => {
      if (isAdmin) {
        return (
          option.label !== 'Contact Us' &&
          option.label !== 'Service Provider Profile' &&
          option.label !== 'Billing & Payments'
        );
      }

      if (isProjectManager) {
        return (
          option.label !== 'Contact Us' &&
          option.label !== 'Service Provider Profile' &&
          option.label !== 'Referrals' &&
          option.label !== 'Billing & Payments' &&
          option.label !== 'Payment Logs' &&
          option.label !== 'Service Provider Report' &&
          option.label !== 'Stripe Users'
        );
      }

      if (isServiceProvider) {
        return (
          option.label !== 'Users Management' &&
          option.label !== 'Referrals' &&
          option.label !== 'Payment Logs' &&
          option.label !== 'Service Provider Report' &&
          option.label !== 'Stripe Users'
        );
      }

      if (isCustomer) {
        return (
          option.label !== 'Users Management' &&
          option.label !== 'Service Provider Profile' &&
          option.label !== 'Referrals' &&
          option.label !== 'Payment Logs' &&
          option.label !== 'Service Provider Report' &&
          option.label !== 'Stripe Users'
        );
      }

      return false;
    });
  }, [currentUser]);

  const firstLevelPath = `/${pathname.split('/')[1]}`;
  const activeOptionIndex = useMemo(() => {
    return filteredOptions.findIndex((option) => option.to === firstLevelPath);
  }, [filteredOptions, firstLevelPath]);

  return (
    <>
      <div
        ref={menuRef}
        className={cn(classes.Menu, {
          [classes.open]: isOpen,
        })}
      >
        <div className={classes.menuContainer}>
          {!isDesktop && (
            <Link to="/engagements">
              <img src={logo} alt="LevelDo" className={classes.logo} />
            </Link>
          )}
          <ul className={classes.list}>
            {filteredOptions.map((option, index) => {
              const isActive = index === activeOptionIndex;
              const inValidPaymentProfile =
                (isCustomer || isServiceProvider) &&
                option.label === 'Billing & Payments' &&
                (isCustomer
                  ? !currentUser.hasChargeMethod
                  : !currentUser.detailsSubmitted);

              let title = '';
              if (inValidPaymentProfile && isCustomer) {
                title = 'No charge method attached.';
              }
              if (inValidPaymentProfile && isServiceProvider) {
                title = 'Onboarding not completed.';
              }
              let unReadMessageCounterBadge = null;

              if (option.label === 'Messaging' && unreadMessageCount > 0) {
                unReadMessageCounterBadge = (
                  <span className={classes.unreadMessageCount}>
                    {unreadMessageCount}
                  </span>
                );
              }

              return (
                <li
                  className={cn(classes.item, {
                    [classes.active]: isActive,
                    [classes.inValidPaymentProfile]: inValidPaymentProfile,
                  })}
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  title={title}
                >
                  <Link
                    target={option.target || ''}
                    onClick={handleLinkClick}
                    to={option.to}
                  >
                    <i className={classes.icon}>
                      {isActive ? option.activeIcon : option.inactiveIcon}
                    </i>
                    <span className={classes.label}>{option.label}</span>
                    {unReadMessageCounterBadge}
                  </Link>
                </li>
              );
            })}
          </ul>
          {!isDesktop && (
            <PrimaryButton
              onClick={handleLogout}
              iconName="logout"
              classnames={[classes.logoutButton]}
            >
              Logout
            </PrimaryButton>
          )}
          <div className={classes.calloutContainer}>
            <p>
              Vetted Pros Used By
              <br /> Top Businesses
            </p>
            <p>
              Get highly skilled, proven, hand-picked professionals deployed
              into your business only when you need them.
            </p>
            <p>- Founder, Alex Clark</p>
          </div>
        </div>
      </div>

      {isOpen && <div onClick={handleMenuClose} className={classes.backdrop} />}
    </>
  );
};

export default Menu;
