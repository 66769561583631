import React from 'react';

import logo from '../../assets/images/logo.svg';
import logoutIcon from '../../assets/icons/logout.svg';

import useUser from '../../hooks/useUser';
import useLogout from '../../hooks/useLogout';
import Avatar from '../UI/Avatar';
import classes from './styles.module.scss';

const Header = ({ title, isUserBadgeVisible }) => {
  const { currentUser } = useUser();

  const handleLogout = useLogout();

  return (
    <header className={classes.Header}>
      <img src={logo} alt="LevelDo" className={classes.logo} />
      <h1 className={classes.title}>{title}</h1>

      {isUserBadgeVisible && (
        <div className={classes.userBadge}>
          <div className={classes.greetingsContainer}>
            <span className={classes.greetings}>
              Hello, {currentUser?.name}
            </span>
            <button
              onClick={handleLogout}
              type="button"
              className={classes.logoutButton}
            >
              <img src={logoutIcon} alt="Logout" />
              Logout
            </button>
          </div>
          <Avatar
            imagePath={currentUser?.avatarPath}
            alt={currentUser?.name}
            className={classes.userAvatar}
          />
        </div>
      )}
    </header>
  );
};

export default Header;
