import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import cn from 'classnames';
import ReactQuill from 'react-quill';
import PageService from '../../services/PageService';
import pen from '../../assets/icons/pen-active.svg';
import close from '../../assets/icons/close.svg';
import 'react-quill/dist/quill.snow.css';

import classes from './styles.module.scss';
import RoundButtonWithIcon from '../UI/Buttons/RoundButtonWithIcon';
import PrimaryButton from '../UI/Buttons/PrimaryButton';

export default function EditablePageContent({
  pageName,
  userRoles,
  contentContainerClassName,
  toolbar,
  placeholder = 'No content',
}) {
  const [content, setContent] = useState('');
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const { isAdmin } = userRoles;
  const placeHolderHtml = `<i class='${classes.placeholder}'>${placeholder}</i>`;

  const {
    data: page,
    isLoading,
    refetch,
  } = useQuery({
    queryFn: () => PageService.getPageByName({ name: pageName }),
    queryKey: [pageName],
  });

  useEffect(() => {
    if (page && page.content) {
      const regex = /(<([^>]+)>)/gi;
      const textContent = page.content.replace(regex, '');
      if (textContent || page.content.includes('iframe')) {
        setContent(page.content);
        return;
      }
    }
    setContent('');
  }, [page]);

  useEffect(() => {
    refetch();
  }, [pageName]);

  const handleEditToggle = () => {
    if (isBeingEdited) setContent(page.content);
    setIsBeingEdited(!isBeingEdited);
  };

  const handleSave = async () => {
    if (!isAdmin) return;
    await PageService.updatePage({ name: pageName, newContent: content });
    setIsBeingEdited(false);
    refetch();
  };

  const modules = {
    toolbar: toolbar || [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ direction: 'rtl' }, { align: [] }],
      ['link', 'image', 'video', 'formula'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  return content || isAdmin ? (
    <div
      className={cn(classes.contentContainer, contentContainerClassName || '')}
    >
      {isAdmin && !isBeingEdited && (
        <RoundButtonWithIcon
          icon={isBeingEdited ? close : pen}
          classnames={[classes.editButton]}
          onClick={handleEditToggle}
        />
      )}

      {!isLoading && !isBeingEdited && isAdmin && (
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: content || placeHolderHtml }}
        />
      )}

      {!isAdmin && (
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}

      {isBeingEdited && (
        <>
          <ReactQuill
            value={content}
            onChange={setContent}
            theme="snow"
            modules={modules}
          />
          <PrimaryButton
            onClick={handleSave}
            iconName="checkmark"
            classnames={[classes.saveButton]}
          >
            Save
          </PrimaryButton>
        </>
      )}
    </div>
  ) : (
    <div />
  );
}
