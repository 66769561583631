import React from 'react';

import UsersItem from './UsersItem';
import classes from './styles.module.scss';

const UsersList = ({
  users,
  handleUserDelete,
  handleUserEdit,
  handleLoginAs,
}) => {
  return (
    <ul className={classes.UsersList}>
      {users.map((user) => (
        <UsersItem
          key={user.id}
          user={user}
          handleUserDelete={handleUserDelete}
          handleUserEdit={handleUserEdit}
          handleLoginAs={handleLoginAs}
        />
      ))}
    </ul>
  );
};

export default UsersList;
