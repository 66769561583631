import React, { useCallback, useState, useEffect } from 'react';

import { PAGE_NAMES, START_SWITCH_OPTIONS } from '../../constants/main';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import useUser from '../../hooks/useUser';
import classes from './styles.module.scss';
import Switch from '../../components/Toolbar/Switch';
import EditablePageContent from '../../components/EditablePageContent/EditablePageContent';

const GettingStartedPage = () => {
  const { isAdmin, isProjectManager, isServiceProvider, isCustomer } =
    useUser();

  const [selectedTab, setSelectedTab] = useState(
    isCustomer || (isServiceProvider && isCustomer)
      ? START_SWITCH_OPTIONS.CUSTOMER
      : START_SWITCH_OPTIONS.SERVICE_PROVIDER
  );
  const [pageNameToFetch, setPageNameToFetch] = useState(
    isServiceProvider && !isCustomer
      ? PAGE_NAMES.SERVICE_PROVIDER_START
      : PAGE_NAMES.CUSTOMER_START
  );

  useEffect(() => {
    if (isServiceProvider && !isCustomer) {
      setSelectedTab(START_SWITCH_OPTIONS.SERVICE_PROVIDER);
    } else {
      setSelectedTab(START_SWITCH_OPTIONS.CUSTOMER);
    }
  }, [isCustomer, isServiceProvider]);

  useEffect(() => {
    if (selectedTab === START_SWITCH_OPTIONS.CUSTOMER) {
      setPageNameToFetch(PAGE_NAMES.CUSTOMER_START);
    } else {
      setPageNameToFetch(PAGE_NAMES.SERVICE_PROVIDER_START);
    }
  }, [selectedTab]);

  const handleSwitchChange = useCallback(
    (option) => {
      setSelectedTab((prevOption) =>
        option === selectedTab ? prevOption : option
      );
    },
    [selectedTab]
  );

  return (
    <div className={classes.gettingStartedPage}>
      <HeaderMenuContainer headerTitle="Getting Started" isUserBadgeVisible>
        <Switch
          customerOption={START_SWITCH_OPTIONS.CUSTOMER}
          providerOption={START_SWITCH_OPTIONS.SERVICE_PROVIDER}
          onChange={handleSwitchChange}
          selectedOption={selectedTab}
          userRoles={{
            isCustomer,
            isServiceProvider,
            isAdmin,
            isProjectManager,
          }}
        />

        <div className={classes.mainIntro}>
          <div>
            <EditablePageContent
              placeholder="Add video here"
              contentContainerClassName={classes.contentContainer}
              pageName={
                selectedTab === START_SWITCH_OPTIONS.CUSTOMER
                  ? PAGE_NAMES.CUSTOMER_START_VIDEO
                  : PAGE_NAMES.SERVICE_PROVIDER_START_VIDEO
              }
              userRoles={{
                isCustomer,
                isServiceProvider,
                isAdmin,
                isProjectManager,
              }}
              toolbar={[['video'], ['clean']]}
            />
          </div>
          <div>
            <EditablePageContent
              placeholder="Add intro here"
              contentContainerClassName={classes.contentContainer}
              pageName={
                selectedTab === START_SWITCH_OPTIONS.CUSTOMER
                  ? PAGE_NAMES.CUSTOMER_START_INTRO
                  : PAGE_NAMES.SERVICE_PROVIDER_START_INTRO
              }
              userRoles={{
                isCustomer,
                isServiceProvider,
                isAdmin,
                isProjectManager,
              }}
              toolbar={[
                [{ header: [1, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ color: [] }, { background: [] }],
                ['clean'],
              ]}
            />
          </div>
        </div>

        <EditablePageContent
          placeholder="Add details here"
          pageName={pageNameToFetch}
          userRoles={{
            isCustomer,
            isServiceProvider,
            isAdmin,
            isProjectManager,
          }}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default GettingStartedPage;
