import React, { useCallback } from 'react';

import cn from 'classnames';
import ModalContainer from '../ModalContainer';
import PrimaryButton from '../../components/UI/Buttons/PrimaryButton';
import NumberCounter from '../../components/Engagements/EngagementsItem/LogHours/NumberCounter';
import classes from './styles.module.scss';

const HoursCounterModal = ({
  title,
  description,
  submitButtonText = 'Submit',
  negativeTimeAllowed = true,
  show,
  handleClose = () => {},
  hours,
  minutes,
  setHours,
  setMinutes,
  hourOffset = 1,
  minuteOffset = 15,
  onSubmit,
}) => {
  const onHoursChange = useCallback((shouldIncrease) => {
    setHours((oldValue) => {
      let newHours = oldValue;
      if (shouldIncrease) {
        newHours = oldValue + hourOffset;
      } else {
        newHours = oldValue - hourOffset;
      }
      if (!negativeTimeAllowed && newHours < 0) {
        return oldValue;
      }
      return newHours;
    });
  }, []);

  const onMinutesChange = useCallback(
    (shouldIncrease) => {
      setMinutes((oldValue) => {
        let totalMinutes = hours * 60 + oldValue;
        if (shouldIncrease) {
          totalMinutes += minuteOffset;
        } else {
          totalMinutes -= minuteOffset;
        }

        if (!negativeTimeAllowed && totalMinutes < 0) {
          return oldValue;
        }

        const totalHours = (totalMinutes / 60).toFixed(2);
        const newHours =
          totalHours < 0 ? Math.ceil(totalHours) : Math.floor(totalHours);
        const newMinutes = totalMinutes % 60;

        setHours(newHours);
        return newMinutes;
      });
    },
    [hours]
  );

  const handleSubmit = useCallback(() => {
    const calculatedHours = hours + minutes / 60;
    onSubmit(calculatedHours);
    if (handleClose) {
      handleClose();
    }
    setHours(0);
    setMinutes(0);
  }, [onSubmit, handleClose]);

  return (
    <ModalContainer
      show={show}
      handleClose={handleClose}
      contentClassName={classes.HoursCounterModal}
      dialogClassName={classes.dialog}
    >
      <div className={classes.content}>
        <h2 className={cn(classes.section, classes.title)}>{title}</h2>

        <div className={cn(classes.section, classes.body)}>
          {!!description && (
            <p className={classes.description}>{description}</p>
          )}
          <div className={classes.counters}>
            <NumberCounter
              label="Hours"
              value={hours}
              onClick={(shouldIncrease) => onHoursChange(shouldIncrease)}
            />
            <NumberCounter
              label="Minutes"
              value={minutes}
              onClick={(shouldIncrease) => onMinutesChange(shouldIncrease)}
            />
          </div>
          <div className={classes.buttons}>
            <PrimaryButton
              classnames={[classes.submitButton]}
              onClick={handleSubmit}
            >
              {submitButtonText}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default HoursCounterModal;
