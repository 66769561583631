import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import Search from '../Dropdown/Search';
import DropdownList from '../Dropdown/DropdownList';
import DropdownOption from './DropdownOption';
import classes from './styles.module.scss';

const GenericDropdown = ({
  items,
  selectedItem,
  label,
  onItemClick,
  onTouched,
  hasError,
  searchValue,
  onSearch,
  clearSearchValue,
  dropdownOptionElement: CustomDropdownOption,
  selectedItems,
  multiSelect = false,
  showAvatar = false,
  classnames = [],
}) => {
  const DropdownOptionElement = CustomDropdownOption || DropdownOption;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);

  useOnClickOutside(dropdownRef, () => {
    if (isDropdownOpen && onTouched) {
      onTouched();
    }

    setIsDropdownOpen(false);
  });

  const handleDropdownToggle = useCallback(() => {
    if (isDropdownOpen && onTouched) {
      onTouched();
    }

    if (!isDropdownOpen && searchInputRef.current) {
      setTimeout(() => searchInputRef.current.focus());
    }

    if (!isDropdownOpen && clearSearchValue) {
      clearSearchValue();
    }

    setIsDropdownOpen((prevState) => !prevState);
  }, [isDropdownOpen, onTouched, clearSearchValue]);

  const handleItemClick = useCallback(
    (item) => {
      onItemClick(item);
      setIsDropdownOpen(false);
    },
    [onItemClick]
  );

  let dropdownButtonContent = (
    <span className={classes.emptyLabel}>{label}</span>
  );

  if (!multiSelect && selectedItem) {
    dropdownButtonContent = (
      <DropdownOptionElement
        item={selectedItem}
        showAvatar={showAvatar}
        isSelected
      />
    );
  }

  if (multiSelect && selectedItems.length) {
    dropdownButtonContent = (
      <>
        {selectedItems.map((x) => (
          <DropdownOptionElement
            key={x.id}
            item={x}
            showAvatar={showAvatar}
            isSelected
          />
        ))}
      </>
    );
  }

  if (isDropdownOpen && onSearch) {
    dropdownButtonContent = (
      <Search
        value={searchValue}
        onSearch={onSearch}
        searchInputRef={searchInputRef}
      />
    );
  }

  return (
    <div
      ref={dropdownRef}
      className={cn(
        classes.GenericDropdown,
        {
          [classes.open]: isDropdownOpen,
          [classes.selected]: selectedItem && !isDropdownOpen,
          [classes.error]: hasError,
        },
        ...classnames
      )}
    >
      <div onClick={handleDropdownToggle} className={classes.dropdownToggle}>
        {dropdownButtonContent}
      </div>
      <DropdownList
        items={items}
        dropdownItem={DropdownOptionElement}
        handleItemClick={handleItemClick}
        isDropdownOpen={isDropdownOpen}
        classnames={[classes.dropdownList]}
        showAvatar={showAvatar}
      />
    </div>
  );
};

export default GenericDropdown;
