import React, { createContext, useState, useMemo, useCallback } from 'react';

import DeleteModal from '../modals/DeleteModal';
import QuillEditorModal from '../modals/QuillEditorModal';
import ErrorModal from '../modals/ErrorModal';
import ConfirmActionModal from '../modals/ConfirmActionModal';

export const UiContext = createContext({
  isMenuOpen: false,
  setIsMenuOpen: () => {},
  showDeleteModal: () => {},
  showQuillEditorModal: () => {},
  isEditorModalOpen: false,
});

const UiContextProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [onConfirm, setOnConfirm] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState(null);
  const [deleteModalType, setDeleteModalType] = useState(null);
  const [isQuillEditorModalOpen, setIsQuillEditorModalOpen] = useState(false);
  const [quillEditorModalProps, setQuillEditorModalProps] = useState({
    html: '',
    setHtml: () => {},
  });
  const [isConfirmActionModalOpen, setIsConfirmActionModalOpen] =
    useState(false);
  const [confirmActionModalTitle, setConfirmActionModalTitle] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const showDeleteModal = useCallback(({ data, handleDelete, type }) => {
    setDataToDelete(data);
    setOnConfirm(() => handleDelete);
    setDeleteModalType(type);
    setIsDeleteModalOpen(true);
  }, []);

  const showQuillEditorModal = useCallback(
    ({ html, setHtml, handleUpdate }) => {
      setQuillEditorModalProps({ html, setHtml });
      setOnConfirm(() => handleUpdate);
      setIsQuillEditorModalOpen(true);
    },
    []
  );

  const showConfirmActionModal = useCallback(({ title, handleSubmit }) => {
    setConfirmActionModalTitle(title);
    setOnConfirm(() => handleSubmit);
    setIsConfirmActionModalOpen(true);
  }, []);

  const showErrorModal = useCallback(({ message }) => {
    setErrorModalMessage(message);
    setIsErrorModalOpen(true);

    setTimeout(() => {
      setIsErrorModalOpen(false);
    }, 3000);
  }, []);

  const value = useMemo(
    () => ({
      isMenuOpen,
      setIsMenuOpen,
      showDeleteModal,
      showQuillEditorModal,
      isEditorModalOpen: isQuillEditorModalOpen,
      showConfirmActionModal,
      showErrorModal,
    }),
    [
      isMenuOpen,
      setIsMenuOpen,
      showDeleteModal,
      showQuillEditorModal,
      isQuillEditorModalOpen,
      showConfirmActionModal,
      showErrorModal,
    ]
  );

  return (
    <UiContext.Provider value={value}>
      {children}
      <DeleteModal
        show={isDeleteModalOpen}
        data={dataToDelete}
        handleConfirm={onConfirm}
        type={deleteModalType}
        handleClose={() => setIsDeleteModalOpen(false)}
      />
      <QuillEditorModal
        show={isQuillEditorModalOpen}
        handleClose={() => setIsQuillEditorModalOpen(false)}
        handleConfirm={onConfirm}
        html={quillEditorModalProps.html}
        setHtml={quillEditorModalProps.setHtml}
      />
      <ConfirmActionModal
        show={isConfirmActionModalOpen}
        title={confirmActionModalTitle}
        handleClose={() => setIsConfirmActionModalOpen(false)}
        handleConfirm={onConfirm}
      />
      <ErrorModal
        show={isErrorModalOpen}
        message={errorModalMessage}
        handleClose={() => setIsErrorModalOpen(false)}
      />
    </UiContext.Provider>
  );
};

export default UiContextProvider;
