import React from 'react';

import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import classes from './styles.module.scss';
import { PAGE_NAMES } from '../../constants/main';
import useUser from '../../hooks/useUser';
import EditablePageContent from '../../components/EditablePageContent/EditablePageContent';

const ReferralsPage = () => {
  const { isAdmin } = useUser();

  return (
    <div className={classes.ReferralsPage}>
      <HeaderMenuContainer headerTitle="Referrals" isUserBadgeVisible>
        <div className={classes.title}>Referrals</div>
        <EditablePageContent
          pageName={PAGE_NAMES.REFFERALS}
          userRoles={{
            isAdmin,
          }}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default ReferralsPage;
