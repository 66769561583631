import React from 'react';
import Cookies from 'js-cookie';
import { useQuery } from 'react-query';
import LoginContainer from '../../containers/LoginContainer';
import UserService from '../../services/UserService';
import LoginForm from '../../components/LoginForm';
import classes from './styles.module.scss';

const LoginPage = () => {
  const {
    data,
    isLoading: isUserLoading,
    isSuccess,
  } = useQuery('currentUser', UserService.getMe, {
    staleTime: Infinity,
    retry: 0,
    enabled: !!Cookies.get('login-path'),
  });

  if (isUserLoading) {
    return null;
  }

  if (isSuccess && data) {
    window.location.replace('/getting-started');
    return null;
  }

  return (
    <div className={classes.LoginPage}>
      <LoginContainer>
        <LoginForm />
      </LoginContainer>
    </div>
  );
};

export default LoginPage;
