import React, { useCallback, useState, useEffect, useContext } from 'react';

import { useMutation, useQuery } from 'react-query';

import { ENGAGEMENTS_SWITCH_OPTIONS, USER_ROLES } from '../../constants/main';
import {
  engagementStatusOptionsForCustomer,
  engagementStatusOptionsForServiceProviders,
} from '../../constants/options';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import UserService from '../../services/UserService';
import EngagementService from '../../services/EngagementService';
import useUser from '../../hooks/useUser';
import useSearchState from '../../hooks/useSearchState';
import Toolbar from '../../components/Toolbar';
import Pagination from '../../components/Pagination';
import EngagementsList from '../../components/Engagements/EngagementsList';
import classes from './styles.module.scss';
import { UiContext } from '../../context/UiContext';
import ChatService from '../../services/ChatService';
import CreateOrUpdateConversationModal from '../../modals/CreateOrUpdateConversationModal';

const EngagementsPage = () => {
  const { isAdmin, isProjectManager, isServiceProvider, isCustomer } =
    useUser();
  const [
    shouldCreateOrUpdateConversationModalOpen,
    setShouldCreateOrUpdateConversationModalOpen,
  ] = useState(false);
  const [currentEngagementData, setCurrentEngagementData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState(
    isServiceProvider && !isCustomer
      ? ENGAGEMENTS_SWITCH_OPTIONS.CUSTOMERS
      : ENGAGEMENTS_SWITCH_OPTIONS.HIRES
  );
  const [filterOptions, setFilterOptions] = useState([]);
  const { showErrorModal } = useContext(UiContext);

  const isServiceProviderView =
    selectedTab === ENGAGEMENTS_SWITCH_OPTIONS.CUSTOMERS && isServiceProvider;

  const [
    engagementsSearch,
    debouncedEngagementsSearch,
    handleEngagementsSearchChange,
    clearEngagementsSearch,
  ] = useSearchState();
  const [
    serviceProvidersSearch,
    debouncedServiceProvidersSearch,
    handleServiceProvidersSearchChange,
    clearServiceProvidersSearch,
  ] = useSearchState();

  const pageSize = 11;

  const {
    data,
    refetch: refetchEngagements,
    isFetched,
  } = useQuery(
    [
      'engagements',
      currentPage,
      debouncedEngagementsSearch,
      selectedTab,
      filterOptions,
    ],
    () =>
      EngagementService.getEngagements({
        page: currentPage,
        limit: pageSize,
        search: debouncedEngagementsSearch,
        type: selectedTab,
        statuses: filterOptions,
      }),
    {
      keepPreviousData: true,
    }
  );

  const { data: serviceProviders } = useQuery(
    ['serviceProviders', debouncedServiceProvidersSearch],
    () =>
      UserService.getUsers({
        roles: [USER_ROLES.SERVICE_PROVIDER],
        search: debouncedServiceProvidersSearch,
      }),
    {
      keepPreviousData: true,
      enabled: isAdmin || isProjectManager,
    }
  );

  const { mutate: updateEngagementMutation } = useMutation(
    EngagementService.updateEngagement,
    {
      onSuccess: () => {
        refetchEngagements();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: createEngagementUserMutation } = useMutation(
    EngagementService.createEngagementUser,
    {
      onSuccess: () => {
        refetchEngagements();
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { mutate: addHoursToEngagementUserMutation } = useMutation(
    EngagementService.addHoursToEngagementUser,
    {
      onSuccess: () => {
        refetchEngagements();
      },
      onError: (error) => {
        console.log(error);
        showErrorModal({
          message: `You are trying to bill either more than maximum billiable hours or negative hours. Contact your admin for any questions.`,
        });
      },
    }
  );

  const { mutate: createOrUpdateConversationMutation } = useMutation(
    ChatService.createOrUpdateConversation,
    {
      onSuccess: () => {
        refetchEngagements();
        shouldCreateOrUpdateConversationModalOpen(false);
      },
      onError: (error) => {
        showErrorModal({
          message: error.response.data.message || error.response.data.error,
        });
      },
    }
  );

  const { mutate: updateConversationStatusMutation } = useMutation(
    ChatService.updateConversationStatus,
    {
      onSuccess: () => {
        refetchEngagements();
      },
      onError: (error) => {
        console.log(error);
        showErrorModal({
          message: error.response.data.message || error.response.data.error,
        });
      },
    }
  );

  const { mutate: deleteConversationMutation } = useMutation(
    ChatService.deleteConversation,
    {
      onSuccess: () => {
        refetchEngagements();
      },
      onError: (error) => {
        console.log(error);
        showErrorModal({
          message: error.response.data.message || error.response.data.error,
        });
      },
    }
  );

  const handleCreateOrUpdateConversation = useCallback(
    (createConversationData) => {
      createOrUpdateConversationMutation(createConversationData);
    },
    [currentEngagementData, createOrUpdateConversationMutation]
  );

  useEffect(() => {
    if (isCustomer) {
      setSelectedTab(ENGAGEMENTS_SWITCH_OPTIONS.HIRES);
    } else if (isServiceProvider) {
      setSelectedTab(ENGAGEMENTS_SWITCH_OPTIONS.CUSTOMERS);
    }
  }, [isCustomer, isServiceProvider]);

  useEffect(() => {
    if (debouncedEngagementsSearch || filterOptions.length) {
      setCurrentPage(1);
    }
  }, [debouncedEngagementsSearch, filterOptions]);

  const handlePageChange = useCallback((event) => {
    const { selected } = event;

    window.scrollTo(0, 0);
    setCurrentPage(selected + 1);
  }, []);

  const handleSwitchChange = useCallback(
    (option) => {
      if (option === selectedTab) {
        return;
      }

      setSelectedTab(option);
      clearEngagementsSearch();
      setCurrentPage(1);
    },
    [selectedTab, clearEngagementsSearch]
  );

  const handleFilterOptionsChange = useCallback((event) => {
    const { checked, value } = event.target;

    if (checked) {
      setFilterOptions((prevState) => [...prevState, value]);
    } else {
      setFilterOptions((prevState) =>
        prevState.filter((option) => option !== value)
      );
    }
  }, []);

  return (
    <div className={classes.EngagementsPage}>
      <HeaderMenuContainer headerTitle="Engagements" isUserBadgeVisible>
        <Toolbar
          title="Engagements"
          searchValue={engagementsSearch}
          handleSearchValueChange={handleEngagementsSearchChange}
          hasCreateButton={isAdmin || isProjectManager}
          createButtonLabel="New Engagement"
          createButtonLink="/engagements/create"
          hasSwitch
          hasSearch
          hasFilter
          filterOptions={
            isServiceProviderView
              ? engagementStatusOptionsForServiceProviders
              : engagementStatusOptionsForCustomer
          }
          handleFilterOptionsChange={handleFilterOptionsChange}
          selectedSwitchOption={selectedTab}
          onSwitchChange={handleSwitchChange}
          userRoles={{ isCustomer, isServiceProvider }}
        />

        <div className={classes.engagementsListContainer}>
          {isFetched && (
            <EngagementsList
              engagements={data.engagements}
              serviceProviders={serviceProviders}
              handleUpdate={updateEngagementMutation}
              handleServiceProviderSearchValueChange={
                handleServiceProvidersSearchChange
              }
              serviceProviderSearchValue={serviceProvidersSearch}
              clearServiceProviderSearchValue={clearServiceProvidersSearch}
              createEngagementUserMutation={createEngagementUserMutation}
              selectedTab={selectedTab}
              addHoursToEngagementUserMutation={
                addHoursToEngagementUserMutation
              }
              setCurrentEngagementData={setCurrentEngagementData}
              setShouldCreateOrUpdateConversationModalOpen={
                setShouldCreateOrUpdateConversationModalOpen
              }
              updateConversationStatusMutation={
                updateConversationStatusMutation
              }
              deleteConversationMutation={deleteConversationMutation}
            />
          )}
        </div>

        <Pagination
          pageCount={data?.pageCount}
          handlePageChange={handlePageChange}
        />
        <CreateOrUpdateConversationModal
          show={shouldCreateOrUpdateConversationModalOpen}
          engagementData={currentEngagementData}
          onSubmit={handleCreateOrUpdateConversation}
          handleClose={() =>
            setShouldCreateOrUpdateConversationModalOpen(false)
          }
          showErrorModal={showErrorModal}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default EngagementsPage;
