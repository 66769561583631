/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import arrowIconWhite from '../../../../assets/icons/arrow-down-white.svg';
import arrowIcon from '../../../../assets/icons/arrow-down.svg';

import { PAYMENTS_SWITCH_OPTIONS } from '../../../../constants/main';
import useCheckDesktopScreen from '../../../../hooks/useCheckDesktopScreen';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';
import useUser from '../../../../hooks/useUser';

const AMOUNT_COLOR = {
  [PAYMENTS_SWITCH_OPTIONS.GETTING_PAID]: 'green',
  [PAYMENTS_SWITCH_OPTIONS.PAYMENTS]: 'rgba(132, 146, 166, 1)',
};

const BillingHistoryItem = ({ data }) => {
  const { isServiceProvider } = useUser();
  const history = useHistory();
  const isDesktop = useCheckDesktopScreen();

  const {
    type,
    amount,
    refundedAmount,
    name,
    engagementId: id,
    title,
    createdAt,
    stripeReceiptPdfUrl,
  } = data || {};

  const formattedAmount =
    type === PAYMENTS_SWITCH_OPTIONS.GETTING_PAID
      ? `+$${amount}`
      : `-$${amount}`;

  const formattedRefundedAmount =
    type === PAYMENTS_SWITCH_OPTIONS.GETTING_PAID
      ? `-$${refundedAmount}`
      : `+$${refundedAmount}`;

  const refundedAmountType =
    type === PAYMENTS_SWITCH_OPTIONS.GETTING_PAID
      ? PAYMENTS_SWITCH_OPTIONS.PAYMENTS
      : PAYMENTS_SWITCH_OPTIONS.GETTING_PAID;

  const handleOpenEngagement = useCallback(() => {
    history.push(`/engagements/${id}`);
  }, [id, history]);

  const handleDownloadReceiptPdf = useCallback(() => {
    const link = document.createElement('a');
    link.href = stripeReceiptPdfUrl;
    if (type === PAYMENTS_SWITCH_OPTIONS.GETTING_PAID) {
      link.target = '_blank';
    }
    link.dispatchEvent(new MouseEvent('click'));
  }, [id, history]);

  return (
    <li
      onClick={() => {
        if (!isDesktop) {
          handleOpenEngagement();
        }
      }}
      className={classes.BillingHistoryItem}
    >
      <div className={classes.paymentLog}>
        <span title={title} className={classes.title}>
          {name}
          {' - '}
          {title}
        </span>
        <span className={classes.createdAt}>
          {moment(createdAt).format('MMMM D')}
        </span>
        <span className={classes.amount}>
          <span
            className={classes.mainAmount}
            style={{ backgroundColor: AMOUNT_COLOR[type] }}
          >
            {formattedAmount}
          </span>
          {refundedAmount > 0 && (
            <span
              className={classes.refundedAmount}
              style={{ backgroundColor: AMOUNT_COLOR[refundedAmountType] }}
            >
              {formattedRefundedAmount}
            </span>
          )}
        </span>
        {type === PAYMENTS_SWITCH_OPTIONS.PAYMENTS && (
          <RoundButtonWithIcon
            disabled={!stripeReceiptPdfUrl}
            onClick={handleDownloadReceiptPdf}
            classnames={[classes.downloadInvoiceButton]}
            icon={arrowIconWhite}
          />
        )}
      </div>

      {!isServiceProvider && (
        <RoundButtonWithIcon
          onClick={handleOpenEngagement}
          classnames={[classes.openEngagementButton]}
          icon={arrowIcon}
        />
      )}
    </li>
  );
};

export default BillingHistoryItem;
