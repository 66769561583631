import React from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import 'react-quill/dist/quill.core.css';

import PublicProfileLinkService from '../../services/PublicProfileLinkService';
import classes from './styles.module.scss';

const PublicProfilePage = () => {
  const { userId, linkId } = useParams();

  const {
    data: validateResult,
    isFetching,
    isError,
  } = useQuery(
    'profileLinkValidate',
    () =>
      PublicProfileLinkService.validateLink({
        userId,
        linkUid: linkId,
      }),
    {
      retry: false,
    }
  );

  if (isFetching) {
    return null;
  }

  if (isError) {
    window.location.replace('https://form.jotform.com/241376643304151');
    return null;
  }

  return (
    <div className={classes.PublicProfilePage}>
      <div
        className="view ql-editor"
        dangerouslySetInnerHTML={{ __html: validateResult?.details }}
      />
    </div>
  );
};

export default PublicProfilePage;
