import React from 'react';
import classes from './styles.module.scss';

const Tr = ({ columns, data }) => {
  return (
    <tr className={classes.Tr}>
      {columns.map((column) => {
        const columnValue = column.accessor ? data[column.accessor] : null;
        return (
          <td
            key={column.accessor || column.label}
            className={column.tdClass || ''}
          >
            <div>
              {column.renderer
                ? column.renderer(columnValue, data)
                : columnValue || ''}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default Tr;
