import React from 'react';
import cn from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import classes from './styles.module.scss';

export default function QuillTextEditor({
  hasError,
  contentContainerClassName,
  content,
  onChange,
  toolbar,
  ...rest
}) {
  const modules = {
    toolbar: toolbar || [
      [{ font: [] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ header: 1 }, { header: 2 }, 'blockquote', 'code-block'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      [{ direction: 'rtl' }, { align: [] }],
      ['link', 'image', 'video', 'formula'],
      ['clean'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <ReactQuill
      className={cn(
        classes.contentContainer,
        {
          [classes.error]: hasError,
        },
        contentContainerClassName || ''
      )}
      value={content}
      onChange={onChange}
      theme="snow"
      modules={modules}
      {...rest}
    />
  );
}
