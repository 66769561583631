import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa6';
import { SORT_OPTIONS } from '../../../constants/main';
import classes from './styles.module.scss';

const Th = ({ column, handleSortChange }) => {
  const [sort, setSort] = useState(SORT_OPTIONS.NONE);
  const isSortable = column.sortable !== false;
  const sortIcon = () => {
    switch (sort) {
      case SORT_OPTIONS.ASC:
        return <FaSortUp />;
      case SORT_OPTIONS.DESC:
        return <FaSortDown />;
      default:
        return null;
    }
  };
  const handleSortingChange = () => {
    setSort((oldSort) => {
      switch (oldSort) {
        case SORT_OPTIONS.ASC:
          return SORT_OPTIONS.DESC;
        case SORT_OPTIONS.DESC:
          return SORT_OPTIONS.NONE;
        default:
          return SORT_OPTIONS.ASC;
      }
    });
  };
  useEffect(() => {
    handleSortChange({
      column: column.accessor,
      type: sort,
    });
  }, [column.accessor, sort]);
  return (
    <th
      className={cn(classes.Th, column.thClass || '', {
        [classes.sortable]: isSortable,
      })}
      onClick={() => isSortable && handleSortingChange()}
    >
      <div>
        <span>{column.label}</span>
        {isSortable && (
          <span>
            <span>
              <FaSort className={classes.sortNone} />
            </span>
            <span>{sortIcon()}</span>
          </span>
        )}
      </div>
    </th>
  );
};

export default Th;
