import React, { useCallback, useContext, useState } from 'react';

import cn from 'classnames';
import { Session } from '@talkjs/react';

import { UiContext } from '../../context/UiContext';
import Menu from '../../components/Menu';
import menuIcon from '../../assets/icons/menu.svg';
import Header from '../../components/Header';
import TopHeader from '../../components/TopHeader';
import classes from './styles.module.scss';
import useUser from '../../hooks/useUser';
import { CHAT_APP_ID } from '../../constants/main';
import useCheckDesktopScreen from '../../hooks/useCheckDesktopScreen';

const HeaderMenuContainer = ({
  headerTitle,
  isUserBadgeVisible,
  contentClassname,
  children,
}) => {
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const isDesktop = useCheckDesktopScreen();
  const { isMenuOpen, setIsMenuOpen } = useContext(UiContext);
  const { currentUser } = useUser();

  const handleMenuToggle = useCallback(() => {
    setIsMenuOpen((prevState) => !prevState);
  }, []);

  const handleMenuClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const mainContent = () => (
    <div className={classes.HeaderMenuContainer}>
      {!isDesktop && (
        <button
          onClick={handleMenuToggle}
          type="button"
          className={classes.menuButton}
        >
          <img src={menuIcon} alt="Menu" />
        </button>
      )}
      <Menu
        handleMenuToggle={handleMenuToggle}
        handleMenuClose={handleMenuClose}
        isOpen={isMenuOpen}
        unreadMessageCount={unreadMessageCount}
      />
      <div className={classes.container}>
        <Header
          handleMenuToggle={handleMenuToggle}
          title={headerTitle}
          isUserBadgeVisible={isUserBadgeVisible}
        />
        <main className={cn(classes.content, contentClassname)}>
          {children}
        </main>
      </div>
    </div>
  );

  const renderWithTalkJsSession = () => {
    if (currentUser.chatUserId) {
      return (
        <Session
          appId={CHAT_APP_ID}
          userId={currentUser.chatUserId}
          signature={currentUser.chatSignature}
          onUnreadsChange={(messages) => {
            const count = messages
              .map((x) => x.unreadMessageCount)
              .reduce((prev, curr) => curr + prev, 0);
            setUnreadMessageCount(count);
          }}
        >
          {mainContent()}
        </Session>
      );
    }
    return mainContent();
  };

  return (
    <div>
      <TopHeader unreadMessageCount={unreadMessageCount} />
      {renderWithTalkJsSession()}
    </div>
  );
};

export default HeaderMenuContainer;
