import React from 'react';

import { Link } from 'react-router-dom';

import useUser from '../../hooks/useUser';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import InfoIcon from '../../components/UI/InfoIcon';
import classes from './styles.module.scss';

const ServiceProviderProfilePage = () => {
  const { currentUser } = useUser();

  return (
    <div className={classes.ServiceProviderProfilePage}>
      <HeaderMenuContainer
        headerTitle="Service Provider Profile"
        isUserBadgeVisible
      >
        <div className={classes.infoContainer}>
          <h1 className={classes.title}>
            Your Billable Rate Is Private & Not Shared with Current or
            Prospective Customers.
          </h1>
          <p className={classes.info}>
            <span className={classes.hourlyRateTitle}>
              Your Billable Hourly Rate:
            </span>
            <span className={classes.hourlyRateValue}>
              ${currentUser?.rate} / hour
            </span>
            <Link
              target="_blank"
              to={{ pathname: 'https://form.jotform.com/240906087684061' }}
              className={classes.hourlyRateButton}
            >
              Request Hourly Rate Update
            </Link>
          </p>
          <div className={classes.billingInfo}>
            <InfoIcon />
            <p className={classes.text}>
              Note: The customer will see a different billing rate based on any
              LevelDo markup percentages and fees in accordance with the
              LevelDo.Pros service agreement. Negotiating or discussing billing
              rates outside of the agreement with customers is strictly
              prohibited. Direct all customers to contact LevelDo. Pros support
              to discuss engagement billing & rates.
            </p>
          </div>
        </div>
        <Link
          to={`public-profile/${currentUser.id}`}
          target="_blank"
          className={classes.privateProfileLink}
        >
          View Your Private Profile
        </Link>
      </HeaderMenuContainer>
    </div>
  );
};

export default ServiceProviderProfilePage;
