import React, { useState, useEffect, useRef } from 'react';

import cn from 'classnames';

import checkmark from '../../../../assets/icons/checkmark.svg';

import QuillTextEditor from '../../../QuillTextEditor/QuillTextEditor';
import RoundButtonWithIcon from '../../../UI/Buttons/RoundButtonWithIcon';
import classes from './styles.module.scss';

const Description = ({
  description,
  isEditable,
  handleUpdate,
  isEngagementEditing,
  isEngagementOpen,
  isServiceProviderView,
}) => {
  const [newDescription, setNewDescription] = useState(description);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isReadMore, setIsReadMore] = useState(false);

  const descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) {
      const element = descriptionRef.current;

      setIsOverflowing(element.offsetHeight < element.scrollHeight);
    }
  }, [descriptionRef, isReadMore, description]);

  useEffect(() => {
    if (!isEngagementEditing) {
      setNewDescription(description);
    }
  }, [isEngagementEditing, description]);

  let content;

  if (isEditable) {
    content = (
      <div className={classes.textareaContainer}>
        <QuillTextEditor
          content={newDescription}
          onChange={setNewDescription}
          contentContainerClassName={classes.textareaContainer}
        />
        <RoundButtonWithIcon
          onClick={() => handleUpdate(newDescription)}
          classnames={[classes.descriptionButton]}
          icon={checkmark}
          iconAlt="Update description"
        />
      </div>
    );
  } else {
    content = (
      <>
        {isServiceProviderView && (
          <span className={classes.title}>Your engagement:</span>
        )}
        <div>
          <p
            className={cn('ql-editor', classes.text, {
              [classes.more]: isReadMore,
            })}
            ref={descriptionRef}
            dangerouslySetInnerHTML={{ __html: description }}
          />

          {isOverflowing && (
            <span
              onClick={() => setIsReadMore(true)}
              className={classes.readMore}
            >
              Read More
            </span>
          )}
        </div>
      </>
    );
  }

  return (
    <div
      className={cn(classes.Description, {
        [classes.open]: isEngagementOpen,
      })}
    >
      {content}
    </div>
  );
};

export default Description;
