import React from 'react';

import cn from 'classnames';

import PrimaryButton from '../../../../UI/Buttons/PrimaryButton';
import classes from './styles.module.scss';

const LogItem = ({ log, handleUpdate, handleDelete, oddLast }) => {
  return (
    <li
      className={cn(classes.LogItem, {
        [classes.resolvable]: log.resolvable,
        [classes.oddLast]: oddLast,
      })}
    >
      <div className={classes.logInfo}>
        <span>Id:</span>
        <span>{log.id}</span>
      </div>
      <div className={classes.logInfo}>
        <span>Engagement Id:</span>
        <span>{log.engagementId}</span>
      </div>
      <div className={classes.logInfo}>
        <span>Engagement title:</span>
        <span>{log.title}</span>
      </div>
      <div className={classes.logInfo}>
        <span>Error:</span>
        <span>{log.stripeErrorMessage}</span>
      </div>
      <div className={classes.logInfo}>
        <span>Amount:</span>
        <span>${log.amount}</span>
      </div>
      {!log.resolvable && log.parent && (
        <div className={classes.logInfo}>
          <span>Parent Log Id:</span>
          <span>{log.parent}</span>
        </div>
      )}
      <div className={classes.logInfo}>
        <span>Stripe {log.parent ? 'Account' : 'Customer'} Id:</span>
        <span>{log.stripeCustomerOrAccountId}</span>
      </div>
      <div className={classes.logInfo}>
        <span>Stripe {log.parent ? 'Refund' : 'Charge'} Id:</span>
        <span>
          {log.parent && log.stripeTransactionId
            ? log.stripeTransactionId
            : 'NA'}
        </span>
      </div>
      <div className={classes.logInfo}>
        <span>User name:</span>
        <span>{log.name}</span>
      </div>
      <div className={classes.logInfo}>
        <span>User email:</span>
        <span>{log.email}</span>
      </div>
      <div className={classes.buttonContainer}>
        <PrimaryButton
          disabled={!log.resolvable && !log.parent}
          onClick={() => handleUpdate(log.id, !!log.parent)}
          classnames={[classes.updateButton]}
        >
          Mark as completed
        </PrimaryButton>
        <PrimaryButton
          disabled={log.resolvable && !!log.parent}
          onClick={() => handleDelete(log.id)}
          classnames={[classes.deleteButton]}
        >
          Delete
        </PrimaryButton>
      </div>
    </li>
  );
};

export default LogItem;
