import React from 'react';

import EngagementsItem from '../EngagementsItem';
import classes from './styles.module.scss';

const EngagementsList = ({
  engagements,
  serviceProviders,
  handleUpdate,
  handleServiceProviderSearchValueChange,
  serviceProviderSearchValue,
  clearServiceProviderSearchValue,
  createEngagementUserMutation,
  selectedTab,
  addHoursToEngagementUserMutation,
  setCurrentEngagementData,
  setShouldCreateOrUpdateConversationModalOpen,
  updateConversationStatusMutation,
  deleteConversationMutation,
}) => {
  return (
    <>
      {engagements.length === 0 && (
        <p className={classes.noEngagementText}>
          There are currently no engagements to display. Contact us or chat with
          your deidcated PM.
        </p>
      )}
      <ul className={classes.EngagementsList}>
        {engagements.map((engagement) => (
          <EngagementsItem
            key={engagement.id}
            engagement={engagement}
            serviceProviders={serviceProviders}
            handleServiceProviderSearchValueChange={
              handleServiceProviderSearchValueChange
            }
            serviceProviderSearchValue={serviceProviderSearchValue}
            clearServiceProviderSearchValue={clearServiceProviderSearchValue}
            handleUpdate={handleUpdate}
            createEngagementUserMutation={createEngagementUserMutation}
            selectedTab={selectedTab}
            addHoursToEngagementUserMutation={addHoursToEngagementUserMutation}
            setCurrentEngagementData={setCurrentEngagementData}
            setShouldCreateOrUpdateConversationModalOpen={
              setShouldCreateOrUpdateConversationModalOpen
            }
            updateConversationStatusMutation={updateConversationStatusMutation}
            deleteConversationMutation={deleteConversationMutation}
          />
        ))}
      </ul>
    </>
  );
};

export default EngagementsList;
