import React, { useState, useCallback, useContext, useEffect } from 'react';

import { useQuery, useMutation } from 'react-query';

import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import PaymentService from '../../services/PaymentService';
import useUser from '../../hooks/useUser';
import useSearchState from '../../hooks/useSearchState';
import Toolbar from '../../components/Toolbar';
import Pagination from '../../components/Pagination';
import classes from './styles.module.scss';
import { UiContext } from '../../context/UiContext';
import UpdateDirtyLogModal from '../../modals/UpdateDirtyLogModal';
import LogList from '../../components/Payments/DirtyLogs/LogList';

const DirtyPaymentLogsPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const { isAdmin, isProjectManager } = useUser();
  const { showErrorModal, showDeleteModal } = useContext(UiContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [resolvable, setResolvable] = useState(false);

  const [search, debouncedSearch, handleSearchChange] = useSearchState();

  const { data, refetch, isFetched } = useQuery(
    ['dirtyPaymentLogs', currentPage, debouncedSearch, resolvable],
    () =>
      PaymentService.getDirtyPaymentLogs({
        page: currentPage,
        limit: 11,
        search: debouncedSearch,
        resolvable,
      }),
    {
      keepPreviousData: true,
    }
  );

  const { mutate: markPaymentLogAsCompletedMutation } = useMutation(
    PaymentService.markPaymentLogAsCompleted,
    {
      onSettled: () => {
        setShowModal(false);
      },
      onSuccess: () => {
        refetch();
      },
      onError: (error) => {
        console.log(error);
        showErrorModal({
          message: error.response.data.message || error.response.data.error,
        });
      },
    }
  );

  const { mutate: deleteDirtyLogMutation } = useMutation(
    PaymentService.deleteDirtyLog,
    {
      onSuccess: () => {
        refetch();
      },
      onError: (error) => {
        showErrorModal({
          message: error.response.data.message,
        });
      },
    }
  );

  const handleMarkPaymentLogAsCompleted = useCallback(
    (id, isGettingPaid) => {
      setUpdateData({
        id,
        isGettingPaid,
      });
    },
    [setUpdateData]
  );

  const handleDirtyLogDelete = useCallback(
    (dirtyLogId) => {
      showDeleteModal({
        data: dirtyLogId,
        handleDelete: () => deleteDirtyLogMutation(dirtyLogId),
        type: 'dirty log',
      });
    },
    [showDeleteModal, deleteDirtyLogMutation]
  );

  useEffect(() => {
    if (updateData.id) {
      setShowModal(true);
    }
  }, [updateData]);

  const handlePageChange = useCallback((event) => {
    const { selected } = event;

    window.scrollTo(0, 0);
    setCurrentPage(selected + 1);
  }, []);

  const handleFilterOptionsChange = useCallback((event) => {
    const { checked, value } = event.target;

    if (value === 'resolvable') {
      setResolvable(checked);
    }
  }, []);

  return (
    <div className={classes.DirtyPaymentLogsPage}>
      <UpdateDirtyLogModal
        show={showModal}
        logId={updateData.id}
        isGettingPaid={updateData.isGettingPaid}
        handleClose={() => setShowModal(false)}
        onSubmit={markPaymentLogAsCompletedMutation}
      />
      <HeaderMenuContainer headerTitle="Dirty Payment Logs" isUserBadgeVisible>
        <Toolbar
          title="Dirty Payment Logs"
          searchValue={search}
          handleSearchValueChange={handleSearchChange}
          hasSearch
          hasFilter
          filterOptions={[
            {
              label: 'Resolvable',
              value: 'resolvable',
            },
          ]}
          handleFilterOptionsChange={handleFilterOptionsChange}
          userRoles={{ isAdmin, isProjectManager }}
        />

        <div className={classes.listContainer}>
          {isFetched && (
            <LogList
              logs={data.paymentLogs}
              handleUpdate={handleMarkPaymentLogAsCompleted}
              handleDelete={handleDirtyLogDelete}
            />
          )}
        </div>

        <Pagination
          pageCount={data?.pageCount}
          handlePageChange={handlePageChange}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default DirtyPaymentLogsPage;
