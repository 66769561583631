import React from 'react';

import StripeUsersItem from './StripeUsersItem';
import classes from './styles.module.scss';

const StripeUsersList = ({ stripeUsers, handleStripeUserDelete }) => {
  return (
    <ul className={classes.StripeUsersList}>
      {stripeUsers.map((stripeUser) => (
        <StripeUsersItem
          key={stripeUser.id}
          stripeUser={stripeUser}
          handleStripeUserDelete={handleStripeUserDelete}
        />
      ))}
    </ul>
  );
};

export default StripeUsersList;
