import React from 'react';
import cn from 'classnames';

import Th from './Th';
import Tr from './Tr';
import classes from './styles.module.scss';

const Table = ({
  idColumn,
  columns,
  loading,
  data,
  handleSortChange,
  emptyText,
  emptyClass,
}) => {
  return (
    <table className={classes.Table}>
      <thead>
        <tr>
          {columns.map((column) => {
            return (
              <Th
                key={column.accessor || column.label}
                column={column}
                handleSortChange={handleSortChange || (() => {})}
              />
            );
          })}
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr className={classes.empty}>
            <td colSpan="100%">Loading...</td>
          </tr>
        ) : (
          data.map((d) => {
            return <Tr key={d[idColumn]} columns={columns} data={d} />;
          })
        )}
        {!loading && !data.length && (
          <tr className={cn(classes.empty, emptyClass || '')}>
            <td colSpan="100%">{emptyText || 'No result'}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
