/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';
import { CiLogin } from 'react-icons/ci';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import deleteIcon from '../../../assets/icons/trash-blue.svg';
import editIcon from '../../../assets/icons/pen-active.svg';

import { USER_ROLES, USER_ROLE_MAPPINGS } from '../../../constants/main';
import useCheckDesktopScreen from '../../../hooks/useCheckDesktopScreen';
import useUser from '../../../hooks/useUser';
import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import Avatar from '../../UI/Avatar';
import classes from './styles.module.scss';

const UsersItem = ({
  user,
  handleUserDelete,
  handleUserEdit,
  handleLoginAs,
}) => {
  const { isAdmin, isProjectManager } = useUser();

  const {
    id,
    name,
    roles,
    note,
    email,
    avatarPath,
    hasChargeMethod,
    detailsSubmitted,
  } = user;

  const history = useHistory();
  const isDesktop = useCheckDesktopScreen();

  const handleUserClick = useCallback(() => {
    if (isAdmin && !isDesktop) {
      history.push(`/users-management/${id}/edit`);
    }
  }, []);

  const noChargeExists =
    roles.includes(USER_ROLES.CUSTOMER) && !hasChargeMethod;
  const requiredOnboarding =
    roles.includes(USER_ROLES.SERVICE_PROVIDER) && !detailsSubmitted;

  const formattedRoles = roles
    .map((role) => USER_ROLE_MAPPINGS[role])
    .join(' / ');

  return (
    <li onClick={handleUserClick} className={classes.UsersItem}>
      <div
        className={cn(classes.user, {
          [classes.noChargeExists]: noChargeExists,
          [classes.requiredOnboarding]: requiredOnboarding,
        })}
      >
        <Avatar imagePath={avatarPath} alt={name} />
        <div className={classes.userInfo}>
          <span className={classes.userName}>{name}</span>
          <span className={classes.userRole}>{formattedRoles}</span>
          <span className={classes.note}>{note}</span>
          <span className={classes.userEmail}>{email}</span>
        </div>
      </div>

      {(isAdmin || isProjectManager) && (
        <div className={classes.actions}>
          <RoundButtonWithIcon
            onClick={() => handleUserEdit(user)}
            icon={editIcon}
            iconAlt="Edit"
            classnames={[classes.icon]}
          />
          {isAdmin && (
            <RoundButtonWithIcon
              onClick={() => handleUserDelete(user)}
              icon={deleteIcon}
              iconAlt="Delete"
              classnames={[classes.icon]}
            />
          )}
          {isAdmin && (
            <RoundButtonWithIcon
              onClick={() => handleLoginAs(user.id)}
              iconElement={<CiLogin size={25} />}
              iconAlt="Login as"
              classnames={[classes.icon]}
              title={`Login as ${user.name}`}
            />
          )}
        </div>
      )}
    </li>
  );
};

export default UsersItem;
