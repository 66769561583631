import React from 'react';

import cn from 'classnames';
import avatarPlaceholder from '../../../assets/images/avatar-placeholder.jpg';

import classes from './styles.module.scss';

const Avatar = ({ imagePath, alt, className }) => {
  const avatarImage = imagePath || avatarPlaceholder;

  return (
    <img
      src={avatarImage}
      alt={alt}
      className={cn(classes.Avatar, className)}
    />
  );
};

export default Avatar;
