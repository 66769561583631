import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import UserService from '../../services/UserService';
import { UiContext } from '../../context/UiContext';
import { roleOptions } from '../../constants/options';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import useDebounce from '../../hooks/useDebounce';
import UsersList from '../../components/UsersList';
import Toolbar from '../../components/Toolbar';
import Pagination from '../../components/Pagination';
import classes from './styles.module.scss';

const filterOptions = [
  ...roleOptions,
  { label: 'Customer (Without Payment Method)', value: 'withNoChargeMethod' },
  { label: 'Archived', value: 'archived' },
];

function calculatePageSize() {
  const { innerWidth, innerHeight } = window;

  const isDesktop = innerWidth >= 1200;
  const mobilePageSize = 11;

  if (isDesktop) {
    const headerHeight = 120;
    const toolbarHeight = 90;
    const itemHeight = 80;
    const extraSpace = 132;

    const availableHeight =
      innerHeight - (headerHeight + toolbarHeight + extraSpace);

    return Math.floor(availableHeight / itemHeight);
  }

  return mobilePageSize;
}

const pageSize = calculatePageSize();

const UsersManagementPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [archived, setArchived] = useState(false);
  const [withNoChargeMethod, setWithNoChargeMethod] = useState(false);
  const [filterRoles, setFilterRoles] = useState([]);

  const { showDeleteModal, showErrorModal } = useContext(UiContext);
  const history = useHistory();

  const {
    data,
    refetch: refetchUsers,
    isFetched,
  } = useQuery(
    [
      'users',
      currentPage,
      debouncedSearchValue,
      filterRoles,
      withNoChargeMethod,
      archived,
    ],
    () =>
      UserService.getUsers({
        page: currentPage,
        limit: pageSize,
        search: debouncedSearchValue,
        roles: filterRoles,
        withNoChargeMethod,
        archived,
      }),
    {
      keepPreviousData: true,
    }
  );

  const { mutate: deleteUserMutation } = useMutation(UserService.deleteUser, {
    onSuccess: () => {
      refetchUsers();
    },
    onError: (error) => {
      showErrorModal({
        message: error.response.data.message,
      });
    },
  });

  useEffect(() => {
    if (debouncedSearchValue || filterRoles.length) {
      setCurrentPage(1);
    }
  }, [debouncedSearchValue, filterRoles]);

  const handleSearchValueChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleFilterOptionsChange = useCallback((event) => {
    const { checked, value } = event.target;

    if (value === 'archived') {
      setArchived(checked);
      return;
    }

    if (value === 'withNoChargeMethod') {
      setWithNoChargeMethod(checked);
      return;
    }

    if (checked) {
      setFilterRoles((prevState) => [...prevState, value]);
    } else {
      setFilterRoles((prevState) => prevState.filter((role) => role !== value));
    }
  }, []);

  const handleUserDelete = useCallback(
    (user) => {
      showDeleteModal({
        data: user,
        handleDelete: () => deleteUserMutation(user.id),
        type: 'user',
      });
    },
    [showDeleteModal, deleteUserMutation]
  );

  const handleUserEdit = useCallback(
    (user) => {
      history.push(`/users-management/${user.id}/edit`);
    },
    [history]
  );

  const handleLoginAs = useCallback(async (userId) => {
    await UserService.loginAs(userId);
    window.location.replace('/getting-started');
  }, []);

  const handlePageChange = useCallback((event) => {
    const { selected } = event;

    window.scrollTo(0, 0);
    setCurrentPage(selected + 1);
  }, []);

  return (
    <div className={classes.UsersManagementPage}>
      <HeaderMenuContainer headerTitle="Users" isUserBadgeVisible>
        <Toolbar
          title="Users"
          createButtonLink="/users-management/create"
          createButtonLabel="New user"
          hasFilter
          hasCreateButton
          hasSearch
          filterOptions={filterOptions}
          searchValue={searchValue}
          handleSearchValueChange={handleSearchValueChange}
          handleFilterOptionsChange={handleFilterOptionsChange}
        />

        <div className={classes.usersListContainer}>
          {isFetched && (
            <UsersList
              users={data.users}
              handleUserDelete={handleUserDelete}
              handleUserEdit={handleUserEdit}
              handleLoginAs={handleLoginAs}
            />
          )}
        </div>

        <Pagination
          pageCount={data?.pageCount}
          handlePageChange={handlePageChange}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default UsersManagementPage;
