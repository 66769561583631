/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import deleteIcon from '../../../assets/icons/trash-blue.svg';

import { USER_ROLE_MAPPINGS } from '../../../constants/main';
import useUser from '../../../hooks/useUser';
import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import Avatar from '../../UI/Avatar';
import classes from './styles.module.scss';

const StripeUsersItem = ({ stripeUser, handleStripeUserDelete }) => {
  const { isAdmin, isProjectManager } = useUser();

  const { name, role, email, avatarPath } = stripeUser;

  return (
    <li className={classes.StripeUsersItem}>
      <div className={classes.user}>
        <Avatar imagePath={avatarPath} alt={name} />
        <div className={classes.userInfo}>
          <span className={classes.userName}>{name}</span>
          <span className={classes.userRole}>{USER_ROLE_MAPPINGS[role]}</span>
          <span className={classes.userEmail}>{email}</span>
        </div>
      </div>

      {(isAdmin || isProjectManager) && (
        <div className={classes.actions}>
          {isAdmin && (
            <RoundButtonWithIcon
              onClick={() => handleStripeUserDelete(stripeUser)}
              icon={deleteIcon}
              iconAlt="Delete"
              classnames={[classes.icon]}
            />
          )}
        </div>
      )}
    </li>
  );
};

export default StripeUsersItem;
