import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CiLogin } from 'react-icons/ci';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';
import { useFormik } from 'formik';
import cn from 'classnames';
import { isValidPhoneNumber } from 'react-phone-number-input';

import deleteIcon from '../../assets/icons/trash-blue.svg';

import useUser from '../../hooks/useUser';
import { UiContext } from '../../context/UiContext';
import { USER_ROLES } from '../../constants/main';
import UserService from '../../services/UserService';
import PaymentService from '../../services/PaymentService';
import Input from '../UI/Input';
import PrimaryButton from '../UI/Buttons/PrimaryButton';
import ErrorMessage from '../UI/ErrorMessage';
import AvatarUpload from '../UI/AvatarUpload';
import ArchiveCheckbox from './ArchiveCheckbox';
import RoleCheckboxes from './RoleCheckboxes';
import ServiceProviderDetails from './ServiceProviderDetails';
import StripeCountryDropdown from './ServiceProviderDetails/StripeCountryDropdown';
import PasswordInputs from './PasswordInputs';
import classes from './styles.module.scss';
import validateFileSize from '../../helpers/validateFileSize';
import PhoneNumberInput from '../UI/PhoneNumberInput';

const TYPES = {
  CREATE: 'create',
  EDIT: 'edit',
};

const validationSchema = yup.object({
  fullName: yup.string().trim().required('Full Name is required.'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address.')
    .required('Email is required.'),
  country: yup.string().when('roles', {
    is: (roles) => roles.includes(USER_ROLES.SERVICE_PROVIDER),
    then: (schema) => schema.required('Country is required.'),
  }),
  companyName: yup.string().trim(),
  rate: yup.number().when('roles', {
    is: (roles) => roles.includes(USER_ROLES.SERVICE_PROVIDER),
    then: (schema) => schema.required('Standard Rate is required.'),
  }),
  roles: yup
    .array()
    .min(1, 'At least one role is required.')
    .of(yup.string().required())
    .required(),
  password: yup
    .string()
    .trim()
    .min(8, 'Password must be at least 8 characters.'),
  confirmPassword: yup.string().when('password', {
    is: (password) => password?.length > 0,
    then: (schema) =>
      schema
        .required('Confirm Password is required.')
        .oneOf([yup.ref('password')], 'Passwords must match.'),
  }),
  phoneNumber: yup
    .string()
    .test('invalid-phone-number', 'Invalid phone number.', (value) => {
      return !value || isValidPhoneNumber(value);
    }),
});

const CreateOrEditUserForm = () => {
  const { isAdmin } = useUser();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countrySearchValue, setCountrySearchValue] = useState('');
  const [roles, setRoles] = useState([]);

  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState('');
  const [editorHtml, setEditorHtml] = useState('');

  const queryClient = useQueryClient();
  const history = useHistory();
  const { id } = useParams();
  const {
    showDeleteModal,
    showErrorModal,
    showQuillEditorModal,
    isEditorModalOpen,
  } = useContext(UiContext);

  const type = id ? TYPES.EDIT : TYPES.CREATE;

  const { data: supportedCountries, isFetched: isCountriesFetched } = useQuery(
    'countries',
    PaymentService.getUSCountrySpecs,
    {
      retry: false,
    }
  );
  const stripeCountries = isCountriesFetched
    ? supportedCountries
        .map((x) => ({
          id: x,
          name: x,
        }))
        .filter(
          (x) =>
            !countrySearchValue ||
            x.id.includes(countrySearchValue.toUpperCase())
        )
    : [];

  const { data: user } = useQuery(
    ['user', id],
    () => UserService.getUserById(id),
    {
      enabled: !!id,
    }
  );

  const { mutate: createUserMutation } = useMutation(UserService.createUser, {
    onSuccess: async (data) => {
      if (roles.includes(USER_ROLES.SERVICE_PROVIDER)) {
        await PaymentService.createConnectedAccount(data.userId);
      }

      history.push('/users-management');
    },
    onError: (error) => {
      console.log(error);
      showErrorModal({
        message: error.response.data.message || error.message,
      });
    },
  });

  const { mutate: updateUserMutation } = useMutation(UserService.updateUser, {
    onSuccess: async () => {
      if (isEditorModalOpen) {
        return;
      }

      if (!user.accountExists && roles.includes(USER_ROLES.SERVICE_PROVIDER)) {
        await PaymentService.createConnectedAccount(id);
      }

      history.push('/users-management');
    },
    onError: (error) => {
      console.log(error);
      showErrorModal({
        message: error.response.data.message || error.message,
      });
    },
  });

  const { mutate: deleteUserMutation } = useMutation(UserService.deleteUser, {
    onSuccess: () => {
      history.push('/users-management');
    },
    onError: (error) => {
      showErrorModal({
        message: error.response.data.message,
      });
    },
  });

  useEffect(() => {
    if (type === TYPES.CREATE && user) {
      queryClient.setQueryData('user', null);
    }
  }, [queryClient, type, user]);

  useEffect(() => {
    if (user?.avatarPath) {
      setAvatarPreview(user.avatarPath);
    } else {
      setAvatarPreview('');
    }

    if (user?.details) {
      setEditorHtml(user.details);
    } else {
      setEditorHtml('');
    }
  }, [user]);

  useEffect(() => {
    if (user?.country && isCountriesFetched) {
      setSelectedCountry({
        id: user.country,
        name: user.country,
      });
    }
  }, [user, isCountriesFetched]);

  const handleSubmit = useCallback(
    ({
      fullName,
      email,
      country,
      companyName,
      rate,
      roles: r,
      password,
      note,
      phoneNumber,
      archived,
    }) => {
      setRoles(r);
      if (type === TYPES.EDIT) {
        updateUserMutation({
          id,
          name: fullName,
          email,
          country: country || '',
          companyName: companyName || '',
          rate: rate || '',
          roles: JSON.stringify(r),
          avatar: avatarFile || '',
          details: editorHtml || '',
          ...(password && { password }),
          note: note || '',
          phone: phoneNumber || '',
          archived,
        });
      } else if (type === TYPES.CREATE) {
        createUserMutation({
          name: fullName,
          email,
          country,
          companyName,
          rate,
          roles: JSON.stringify(r),
          avatar: avatarFile,
          details: editorHtml,
          note,
          phone: phoneNumber,
          archived,
        });
      }
    },
    [createUserMutation, updateUserMutation, id, type, avatarFile, editorHtml]
  );

  const initialValues = useMemo(
    () => ({
      fullName: user?.name || '',
      email: user?.email || '',
      country: user?.country || '',
      companyName: user?.companyName || '',
      rate: user?.rate || '',
      roles: user?.roles || [],
      password: '',
      note: user?.note || '',
      phoneNumber: user?.phone || '',
      archived: !!user?.archived,
    }),
    [user, type]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleCountrySelect = useCallback(
    (country) => {
      formik.setFieldValue('country', country.id);
      setSelectedCountry(country);
    },
    [formik]
  );

  const handleCountrySearchValueChange = useCallback((event) => {
    const { value } = event.target;

    setCountrySearchValue(value);
  }, []);

  const clearCountrySearchValue = useCallback(() => {
    setCountrySearchValue('');
  }, []);

  const handleAvatarUploadChange = useCallback((event) => {
    const file = event.target.files[0];

    if (file) {
      if (!validateFileSize(file.size, 5)) {
        showErrorModal({
          message: 'Max file upload size is 5mb.',
        });
        return;
      }

      setAvatarFile(file);

      const reader = new FileReader();

      reader.onload = (e) => {
        setAvatarPreview(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  }, []);

  const handleSelectedRolesChange = useCallback(
    (event) => {
      const { checked, value } = event.target;

      if (checked) {
        formik.setFieldValue('roles', [...formik.values.roles, value]);
      } else {
        const updatedRoles = formik.values.roles.filter(
          (role) => role !== value
        );
        formik.setFieldValue('roles', updatedRoles);
      }
    },
    [formik]
  );

  const handleArchiveChange = useCallback(
    (event) => {
      const { checked } = event.target;
      formik.setFieldValue('archived', checked);
    },
    [formik]
  );

  const handleEditorChange = useCallback(() => {
    showQuillEditorModal({
      html: editorHtml,
      setHtml: setEditorHtml,
      handleUpdate: (details) => {
        if (type === TYPES.CREATE) {
          return;
        }

        updateUserMutation({
          id,
          name: user.name,
          email: user.email,
          country: user.country,
          companyName: user.companyName,
          rate: user.rate,
          roles: JSON.stringify(user.roles),
          avatar: null,
          details,
          note: user.note,
          phone: user.phone,
          archived: user.archived,
        });
      },
    });
  }, [
    showQuillEditorModal,
    editorHtml,
    setEditorHtml,
    updateUserMutation,
    id,
    user,
  ]);

  const handlePublicProfileClick = useCallback(() => {
    window.open(`/public-profile/${user?.id}`, '_blank');
  }, [user]);

  const handleUserDelete = useCallback(() => {
    showDeleteModal({
      data: user,
      handleDelete: () => deleteUserMutation(user.id),
      type: 'user',
    });
  }, [showDeleteModal, deleteUserMutation, user]);

  const handleLoginAs = useCallback(async (userId) => {
    await UserService.loginAs(userId);
    window.location.replace('/getting-started');
  }, []);

  const title = type === TYPES.EDIT ? 'Edit User' : 'Create User';
  const submitButtonText = type === TYPES.EDIT ? 'Save' : 'Create';
  const isServiceProviderSelected = formik.values.roles.includes(
    USER_ROLES.SERVICE_PROVIDER
  );
  const isCustomerSelected = formik.values.roles.includes(USER_ROLES.CUSTOMER);

  return (
    <div className={classes.CreateOrEditUserForm}>
      <form
        className={cn(classes.form, {
          [classes.disabled]: !isAdmin && !isServiceProviderSelected,
        })}
      >
        <h1 className={classes.title}>{title}</h1>
        <div className={classes.formContainer}>
          <div className={classes.avatarAndRolesContainer}>
            <AvatarUpload
              avatarPreview={avatarPreview}
              handleAvatarUploadChange={handleAvatarUploadChange}
            />
            <div className={classes.rolesAndArchiveContainer}>
              <RoleCheckboxes
                handleSelectedRolesChange={handleSelectedRolesChange}
                roles={formik.values.roles}
                isTouched={formik.touched.roles}
                error={formik.errors.roles}
              />
              <ArchiveCheckbox
                handleArchiveChange={handleArchiveChange}
                archived={formik.values.archived}
              />
            </div>
          </div>

          <div className={classes.inputs}>
            <div className={classes.inputContainer}>
              <Input
                value={formik.values.fullName}
                name="fullName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classnames={[classes.input]}
                placeholder="Full Name"
                hasError={formik.touched.fullName && formik.errors.fullName}
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <ErrorMessage message={formik.errors.fullName} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <Input
                value={formik.values.email}
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classnames={[classes.input]}
                placeholder="Email"
                hasError={formik.touched.email && formik.errors.email}
              />
              {formik.touched.email && formik.errors.email && (
                <ErrorMessage message={formik.errors.email} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <Input
                value={formik.values.companyName}
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classnames={[classes.input]}
                placeholder="Company (optional)"
                hasError={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <ErrorMessage message={formik.errors.companyName} />
              )}
            </div>

            <div className={classes.inputContainer}>
              <PhoneNumberInput
                country={formik.values.country}
                value={formik.values.phoneNumber}
                name="phoneNumber"
                onChange={(value) => {
                  formik.setFieldTouched('phoneNumber', true, true);
                  formik.setFieldValue('phoneNumber', value);
                }}
                onBlur={() => formik.setFieldTouched('phoneNumber', true, true)}
                classnames={[classes.input]}
                placeholder="Enter phone number (optional)"
                hasError={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
                error={formik.errors.phoneNumber}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <ErrorMessage message={formik.errors.phoneNumber} />
              )}
            </div>

            {type === TYPES.EDIT && (
              <PasswordInputs
                isPasswordVisible
                passwordValue={formik.values.password}
                confirmPasswordValue={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isPasswordTouched={formik.touched.password}
                isConfirmPasswordTouched={formik.touched.confirmPassword}
                passwordError={formik.errors.password}
                confirmPasswordError={formik.errors.confirmPassword}
              />
            )}
          </div>
        </div>

        {(isServiceProviderSelected || isCustomerSelected) && (
          <div className={classes.midInputs}>
            <div className={classes.inputContainer}>
              <Input
                value={formik.values.note}
                name="note"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                classnames={[classes.input]}
                placeholder="Note"
              />
            </div>
          </div>
        )}

        {isServiceProviderSelected && (
          <ServiceProviderDetails
            userId={user?.id}
            links={user?.links}
            rate={formik.values.rate}
            onRateChange={formik.handleChange}
            onRateBlur={formik.handleBlur}
            isRateTouched={formik.touched.rate}
            rateErrors={formik.errors.rate}
            onEditorChange={handleEditorChange}
            onPublicProfileClick={handlePublicProfileClick}
            isCustomLinkDisabled={type === TYPES.CREATE}
            stripeCountryDropdown={
              <div className={classes.dropdownContainer}>
                <StripeCountryDropdown
                  label="Choose service provider country"
                  items={stripeCountries}
                  selectedItem={selectedCountry}
                  onItemClick={handleCountrySelect}
                  onSearch={handleCountrySearchValueChange}
                  clearSearchValue={clearCountrySearchValue}
                  onTouched={() => formik.setFieldTouched('country', true)}
                  hasError={formik.touched.country && formik.errors.country}
                />
                {formik.touched.country && formik.errors.country && (
                  <ErrorMessage message={formik.errors.country} />
                )}
              </div>
            }
          />
        )}
      </form>
      {isAdmin && (
        <>
          {type === TYPES.EDIT && (
            <button
              onClick={handleUserDelete}
              type="button"
              className={classes.deleteButton}
            >
              <img src={deleteIcon} alt="Delete" />
              Delete user
            </button>
          )}

          <PrimaryButton
            onClick={formik.handleSubmit}
            iconName="arrow"
            classnames={[classes.floatButton, classes.createButton]}
          >
            {submitButtonText}
          </PrimaryButton>
          <PrimaryButton
            onClick={() => handleLoginAs(user?.id)}
            classnames={[classes.floatButton, classes.loginAsButton]}
          >
            <CiLogin size={25} /> Login As {user?.name}
          </PrimaryButton>
        </>
      )}
    </div>
  );
};

export default CreateOrEditUserForm;
