import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '../constants/main';

const globalErrorHandler = (error) => {
  if (
    error.response.data.key?.startsWith('error.auth.') &&
    !['/login', '/admin-login'].includes(window.location.pathname)
  ) {
    window.location.replace(Cookies.get('login-path') || '/login');
  }
  throw error;
};

export default axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

export const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});

export const axiosPrivateFormData = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'multipart/form-data' },
  withCredentials: true,
});

axiosPrivate.interceptors.response.use(
  (response) => response,
  globalErrorHandler
);
axiosPrivateFormData.interceptors.response.use(
  (response) => response,
  globalErrorHandler
);
