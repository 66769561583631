import React from 'react';

import LogItem from './LogItem';
import classes from './styles.module.scss';

const LogList = ({ logs, handleUpdate, handleDelete }) => {
  const lastIndex = logs.length - 1;
  return (
    <ul className={classes.LogList}>
      {logs.map((log, i) => (
        <LogItem
          key={log.id}
          log={log}
          handleUpdate={handleUpdate}
          handleDelete={handleDelete}
          oddLast={lastIndex === i && lastIndex % 2 === 0}
        />
      ))}
    </ul>
  );
};

export default LogList;
