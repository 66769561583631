import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useMutation, useQuery } from 'react-query';

import { UiContext } from '../../context/UiContext';
import { roleOptions } from '../../constants/options';
import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import useDebounce from '../../hooks/useDebounce';
import Toolbar from '../../components/Toolbar';
import Pagination from '../../components/Pagination';
import classes from './styles.module.scss';
import StripeUsersList from '../../components/StripeUsersList';
import { USER_ROLE_MAPPINGS, USER_ROLES } from '../../constants/main';
import PaymentService from '../../services/PaymentService';

const filterOptions = roleOptions.filter((x) =>
  [USER_ROLES.CUSTOMER, USER_ROLES.SERVICE_PROVIDER].includes(x.value)
);

function calculatePageSize() {
  const { innerWidth, innerHeight } = window;

  const isDesktop = innerWidth >= 1200;
  const mobilePageSize = 11;

  if (isDesktop) {
    const headerHeight = 120;
    const toolbarHeight = 90;
    const itemHeight = 80;
    const extraSpace = 132;

    const availableHeight =
      innerHeight - (headerHeight + toolbarHeight + extraSpace);

    return Math.floor(availableHeight / itemHeight);
  }

  return mobilePageSize;
}

const pageSize = calculatePageSize();

const StripeUsers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const [filterRoles, setFilterRoles] = useState([]);

  const { showDeleteModal, showErrorModal } = useContext(UiContext);

  const {
    data,
    refetch: refetchUsers,
    isFetched,
  } = useQuery(
    ['paymentsStripeUsers', currentPage, debouncedSearchValue, filterRoles],
    () =>
      PaymentService.getStripeUsers({
        page: currentPage,
        limit: pageSize,
        search: debouncedSearchValue,
        role: filterRoles.length === 1 ? filterRoles[0] : '',
      }),
    {
      keepPreviousData: true,
    }
  );

  const { mutate: deleteStripeUserMutation } = useMutation(
    PaymentService.deleteStripeUser,
    {
      onSuccess: () => {
        refetchUsers();
      },
      onError: (error) => {
        showErrorModal({
          message: error.response.data.message,
        });
      },
    }
  );

  useEffect(() => {
    if (debouncedSearchValue || filterRoles.length) {
      setCurrentPage(1);
    }
  }, [debouncedSearchValue, filterRoles]);

  const handleSearchValueChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const handleFilterOptionsChange = useCallback((event) => {
    const { checked, value } = event.target;
    if (checked) {
      setFilterRoles((prevState) => [...prevState, value]);
    } else {
      setFilterRoles((prevState) => prevState.filter((role) => role !== value));
    }
  }, []);

  const handleStripeUserDelete = useCallback(
    (stripeUser) => {
      showDeleteModal({
        data: stripeUser,
        handleDelete: () => deleteStripeUserMutation(stripeUser.id),
        type: `stripe user (${USER_ROLE_MAPPINGS[
          stripeUser.role
        ].toLowerCase()})`,
      });
    },
    [showDeleteModal, deleteStripeUserMutation]
  );

  const handlePageChange = useCallback((event) => {
    const { selected } = event;

    window.scrollTo(0, 0);
    setCurrentPage(selected + 1);
  }, []);

  return (
    <div className={classes.StripeUsers}>
      <HeaderMenuContainer headerTitle="Stripe Users" isUserBadgeVisible>
        <Toolbar
          title="Stripe Users"
          hasFilter
          hasSearch
          filterOptions={filterOptions}
          searchValue={searchValue}
          handleSearchValueChange={handleSearchValueChange}
          handleFilterOptionsChange={handleFilterOptionsChange}
        />

        <div className={classes.usersListContainer}>
          {isFetched && (
            <StripeUsersList
              stripeUsers={data.stripeUsers}
              handleStripeUserDelete={handleStripeUserDelete}
            />
          )}
        </div>

        <Pagination
          pageCount={data?.pageCount}
          handlePageChange={handlePageChange}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default StripeUsers;
