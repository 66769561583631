import { axiosPrivate } from '../api/axios';

const ReportService = {
  async getServiceProviderReport({ page, limit, search, filters, sorts }) {
    const response = await axiosPrivate.get(
      '/reporting/service-providers-report',
      {
        params: {
          page,
          limit,
          search,
          filters: JSON.stringify(filters),
          sorts: JSON.stringify(sorts),
        },
      }
    );

    return response.data;
  },
  async getChatReport({ page, limit, search, filters, sorts }) {
    const response = await axiosPrivate.get('/reporting/chat-report', {
      params: {
        page,
        limit,
        search,
        filters: JSON.stringify(filters),
        sorts: JSON.stringify(sorts),
      },
    });

    return response.data;
  },
};

export default ReportService;
