import React from 'react';

import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import CreateOrEditUserForm from '../../components/CreateOrEditUserForm';
import BackButton from '../../components/UI/Buttons/BackButton';
import classes from './styles.module.scss';

const CreateOrEditUserPage = () => {
  return (
    <div className={classes.CreateOrEditUserPage}>
      <HeaderMenuContainer
        isUserBadgeVisible
        contentClassname={classes.content}
      >
        <BackButton label="Back to User Management" />
        <CreateOrEditUserForm />
      </HeaderMenuContainer>
    </div>
  );
};

export default CreateOrEditUserPage;
