import React from 'react';
import PublicProfileLinkItem from './PublicProfileLinkItem';
import classes from './styles.module.scss';

const PublicProfileLinkList = ({ links, handleLinkDelete }) => {
  return (
    <ul className={classes.PublicProfileLinkList}>
      {links.length ? (
        links?.map((link) => (
          <PublicProfileLinkItem
            data={link}
            handleLinkDelete={handleLinkDelete}
            key={link.id}
          />
        ))
      ) : (
        <div className={classes.noLinksFound}>No links found</div>
      )}
    </ul>
  );
};

export default PublicProfileLinkList;
