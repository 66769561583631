import React, { useCallback, useState } from 'react';

import cn from 'classnames';

import useCheckDesktopScreen from '../../../../hooks/useCheckDesktopScreen';
import PrimaryButton from '../../../UI/Buttons/PrimaryButton';
import HoursCounterModal from '../../../../modals/HoursCounterModal';
import classes from './styles.module.scss';

const LogHours = ({ className, onAddHours, serviceProviderStatus }) => {
  const [shouldHoursCounterModalOpen, setShouldHoursCounterModalOpen] =
    useState(false);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);

  const isDesktop = useCheckDesktopScreen();

  const handleSubmit = useCallback(
    (calculatedHours) => {
      onAddHours(calculatedHours);
      setShouldHoursCounterModalOpen(false);
    },
    [onAddHours]
  );

  return (
    <div className={cn(classes.LogHours, className)}>
      <HoursCounterModal
        title="Log New or Edit Hours Worked"
        description="Time can be logged in 15-minute increments. If you accidentally logged too much time for the week before the billing period has ended, you can also subtract time using the down arrows. Ensure to
        log accurate hours as inaccurate reporting may lead to being banned
        from the platform."
        hours={hours}
        minutes={minutes}
        setHours={setHours}
        setMinutes={setMinutes}
        onSubmit={handleSubmit}
        show={shouldHoursCounterModalOpen}
        handleClose={() => setShouldHoursCounterModalOpen(false)}
      />
      <PrimaryButton
        onClick={() => setShouldHoursCounterModalOpen(true)}
        classnames={[classes.logHoursButton]}
        iconName={isDesktop && 'arrow'}
        disabled={serviceProviderStatus !== 'working'}
      >
        Log Hours
      </PrimaryButton>
    </div>
  );
};

export default LogHours;
