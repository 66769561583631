// import axios from 'axios';
import { axiosPrivate } from '../api/axios';

const PaymentService = {
  async createCustomer(stripeToken) {
    const response = await axiosPrivate.post('/payments/customer', {
      stripeToken,
    });

    return response.data;
  },
  async updateCard(stripeToken) {
    const response = await axiosPrivate.patch('/payments/customer', {
      stripeToken,
    });

    return response.data;
  },
  async getCardDetails() {
    const response = await axiosPrivate.get(`/payments/card`);

    return response.data;
  },
  async getBalance() {
    const response = await axiosPrivate.get(`/payments/balance`);

    return response.data;
  },
  async getPaymentLogs({ page, limit, type }) {
    const response = await axiosPrivate.get(`/payments/logs`, {
      params: {
        page,
        limit,
        type,
      },
    });

    return response.data;
  },
  async getDirtyPaymentLogs({ page, limit, search, resolvable }) {
    const response = await axiosPrivate.get(`/payments/dirty-logs`, {
      params: {
        page,
        limit,
        search,
        resolvable,
      },
    });

    return response.data;
  },
  async deleteDirtyLog(id) {
    const response = await axiosPrivate.delete(`/payments/dirty-logs/${id}`);

    return response.data;
  },
  async markPaymentLogAsCompleted({ id, stripeTransactionId }) {
    const response = await axiosPrivate.patch(`/payments/dirty-logs/${id}`, {
      stripeTransactionId,
    });

    return response.data;
  },
  async getUSCountrySpecs() {
    const response = await axiosPrivate.get(`/payments/us-country-specs`);

    return response.data;
  },
  async createConnectedAccount(userId) {
    const response = await axiosPrivate.post('/payments/account', {
      userId,
    });

    return response.data;
  },
  async createConnectedAccountLink(linkType) {
    const response = await axiosPrivate.post(
      `/payments/account-link/${linkType}`
    );

    return response.data;
  },
  async getStripeUsers({ page, limit, search, role }) {
    const response = await axiosPrivate.get('/payments/stripe-users', {
      params: {
        page,
        limit,
        search,
        role,
      },
    });

    return response.data;
  },
  async deleteStripeUser(id) {
    const response = await axiosPrivate.delete(`/payments/stripe-users/${id}`);

    return response.data;
  },
};

export default PaymentService;
