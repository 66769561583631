import React from 'react';

import cn from 'classnames';

import QuillTextEditor from '../../../../../QuillTextEditor/QuillTextEditor';
import classes from './styles.module.scss';

const Responsibilities = ({
  responsibilities,
  isEditable,
  newResponsibilities,
  handleChange,
  isBottom,
}) => {
  let content;

  if (isEditable) {
    content = (
      <QuillTextEditor
        content={newResponsibilities}
        onChange={handleChange}
        contentContainerClassName={classes.textareaContainer}
      />
    );
  } else {
    content = (
      <>
        <span className={classes.title}>Responsibilities:</span>
        <span
          className={cn('ql-editor', classes.content)}
          dangerouslySetInnerHTML={{ __html: responsibilities }}
        />
      </>
    );
  }

  return (
    <div
      className={cn(classes.Responsibilities, { [classes.bottom]: isBottom })}
    >
      {content}
    </div>
  );
};

export default Responsibilities;
