import { useCallback } from 'react';
import Cookies from 'js-cookie';
import { useQueryClient } from 'react-query';

import { logout } from '../helpers/globalAuth';

const useLogout = () => {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    try {
      const loggedOut = await logout();
      if (loggedOut) {
        queryClient.removeQueries();
        window.location.replace(Cookies.get('login-path') || '/login');
      }
    } catch (error) {
      console.log(error);
    }
  }, [queryClient]);
};

export default useLogout;
