/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';

import cn from 'classnames';

import Avatar from '../../Avatar';
import classes from './styles.module.scss';

const DropdownOption = ({ item, handleItemClick, showAvatar, isSelected }) => {
  const handleItemSelect = useCallback(() => {
    if (!isSelected) {
      handleItemClick(item);
    }
  }, [item, isSelected, handleItemClick]);

  return (
    <li
      onClick={handleItemSelect}
      key={item.id}
      className={cn(classes.DropdownOption, {
        [classes.selected]: isSelected,
      })}
    >
      <div className={classes.infoContainer}>
        {showAvatar && (
          <Avatar
            imagePath={item.avatarPath}
            alt={item.label}
            className={classes.avatar}
          />
        )}
        <span className={classes.label}>{item.label}</span>
      </div>
    </li>
  );
};

export default DropdownOption;
