// import axios from 'axios';
import { axiosPrivate } from '../api/axios';

const PUBLIC_PROFILE_LINK_URL = 'profile-link';

const PublicProfileLinkService = {
  async validateLink({ userId, linkUid }) {
    const response = await axiosPrivate.post(
      `/users/${PUBLIC_PROFILE_LINK_URL}/validate`,
      {
        userId,
        linkUid,
      }
    );

    return response.data;
  },
  async createLink({ userId, hours }) {
    const response = await axiosPrivate.post(
      `/users/${PUBLIC_PROFILE_LINK_URL}`,
      {
        userId,
        hours,
      }
    );

    return response.data;
  },
  async deleteLink(id) {
    const response = await axiosPrivate.delete(
      `/users/${PUBLIC_PROFILE_LINK_URL}/${id}`
    );

    return response.data;
  },
};

export default PublicProfileLinkService;
