import React, { useState, useCallback, useRef } from 'react';

import cn from 'classnames';

import searchMobileIcon from '../../../assets/icons/search-white.svg';
import searchDesktopIcon from '../../../assets/icons/search-active.svg';

import RoundButtonWithIcon from '../../UI/Buttons/RoundButtonWithIcon';
import useCheckDesktopScreen from '../../../hooks/useCheckDesktopScreen';
import classes from './styles.module.scss';

const Search = ({ searchValue, handleSearchValueChange }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const isDesktop = useCheckDesktopScreen();

  const searchInputRef = useRef(null);

  const handleSearchToggle = useCallback(() => {
    if (!isDesktop) {
      if (!isSearchOpen) {
        setTimeout(() => searchInputRef.current.focus());
      }

      setIsSearchOpen((prevState) => !prevState);
    }
  }, [isSearchOpen]);

  let searchIcon;

  if (isDesktop) {
    searchIcon = searchDesktopIcon;
  } else if (isSearchOpen) {
    searchIcon = searchDesktopIcon;
  } else {
    searchIcon = searchMobileIcon;
  }

  return (
    <div
      className={cn(classes.Search, {
        [classes.open]: isSearchOpen,
      })}
    >
      <input
        ref={searchInputRef}
        value={searchValue}
        onChange={handleSearchValueChange}
        type="text"
        placeholder="Search"
        className={classes.searchInput}
      />
      <RoundButtonWithIcon
        onClick={handleSearchToggle}
        icon={searchIcon}
        iconAlt="Search"
        iconSize={16}
        classnames={[classes.searchButton]}
      />
    </div>
  );
};

export default Search;
