import React, { useState, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';

import HeaderMenuContainer from '../../containers/HeaderMenuContainer';
import Toolbar from '../../components/Toolbar';
import Pagination from '../../components/Pagination';
import useSearchState from '../../hooks/useSearchState';

import ReportService from '../../services/ReportService';

import Avatar from '../../components/UI/Avatar';

import { engagementStatusOptionsForServiceProviders } from '../../constants/options';

import classes from './styles.module.scss';
import { SORT_OPTIONS } from '../../constants/main';
import Table from '../../components/Table';

const nonArchiveStatuses = engagementStatusOptionsForServiceProviders
  .filter((x) => x.value !== 'archived')
  .map((x) => ({
    ...x,
    filterKey: 'statuses',
  }));

const statusToLabel = {
  [nonArchiveStatuses[0].value]: [nonArchiveStatuses[0].label],
  [nonArchiveStatuses[1].value]: [nonArchiveStatuses[1].label],
  [nonArchiveStatuses[2].value]: [nonArchiveStatuses[2].label],
};

const columnDef = [
  {
    label: 'SP Name',
    accessor: 'spName',
    tdClass: classes.spNameTd,
    renderer: (value, row) => {
      return (
        <div className={classes.spNameContainer}>
          <Avatar
            className={classes.avatar}
            imagePath={row.avatarPath}
            alt={value}
          />
          <span>{value}</span>
        </div>
      );
    },
  },
  {
    label: 'SP Status',
    accessor: 'status',
    renderer: (value) => {
      return statusToLabel[value];
    },
  },
  {
    label: 'Client Name',
    accessor: 'customerName',
  },
  {
    label: 'Engagement Name',
    accessor: 'engagementTitle',
  },
  {
    label: 'Client Rate',
    accessor: 'rate',
    renderer: (value) => {
      return `$${value} / Hour`;
    },
  },
  {
    label: 'SP Rate',
    accessor: 'serviceProviderRate',
    renderer: (value) => {
      return `$${value} / Hour`;
    },
  },
  {
    label: 'Hours This Week',
    accessor: 'hoursThisWeek',
  },
  {
    label: 'Total Hours',
    accessor: 'totalHours',
  },
];

const ServiceProviderReportPage = () => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterOptions, setFilterOptions] = useState({});
  const [sortOptions, setSortOptions] = useState([]);
  const [search, debouncedSearch, handleSearchChange] = useSearchState();

  useEffect(() => {
    if (debouncedSearch || filterOptions.length) {
      setCurrentPage(1);
    }
  }, [debouncedSearch, filterOptions]);

  const { data, isFetched } = useQuery(
    [
      'serviceProviderReport',
      currentPage,
      pageSize,
      debouncedSearch,
      filterOptions,
      sortOptions,
    ],
    () =>
      ReportService.getServiceProviderReport({
        page: currentPage,
        limit: pageSize,
        search: debouncedSearch,
        filters: filterOptions,
        sorts: sortOptions,
      }),
    {
      keepPreviousData: true,
    }
  );

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.id);
  };

  const handlePageChange = useCallback((event) => {
    const { selected } = event;

    window.scrollTo(0, 0);
    setCurrentPage(selected + 1);
  }, []);

  const handleFilterOptionsChange = useCallback((event, filterKey) => {
    const { checked, value } = event.target;

    if (filterKey === 'statuses') {
      setFilterOptions((prevState) => {
        if (checked) {
          return {
            ...prevState,
            statuses: prevState.statuses
              ? [...prevState.statuses, value]
              : [value],
          };
        }
        return {
          ...prevState,
          statuses: prevState.statuses.filter((x) => x !== value),
        };
      });
    }
  }, []);

  const handleSortChange = useCallback((sortOption) => {
    switch (sortOption.type) {
      case SORT_OPTIONS.ASC:
        setSortOptions((oldSortOptions) => {
          const newSortOptions = [...oldSortOptions];
          newSortOptions.push({ ...sortOption });
          return newSortOptions;
        });
        break;
      case SORT_OPTIONS.DESC:
        setSortOptions((oldSortOptions) => {
          return oldSortOptions.map((oldSortOption) => {
            if (oldSortOption.column === sortOption.column) {
              const newSortOption = { ...oldSortOption };
              newSortOption.type = SORT_OPTIONS.DESC;
              return newSortOption;
            }
            return oldSortOption;
          });
        });
        break;
      case SORT_OPTIONS.NONE:
        setSortOptions((oldSortOptions) => {
          return oldSortOptions.filter(
            (oldSortOption) => oldSortOption.column !== sortOption.column
          );
        });
        break;
      default:
        console.log('Invalid sort option.');
        break;
    }
  }, []);

  return (
    <div className={classes.ServiceProviderReportPage}>
      <HeaderMenuContainer
        headerTitle="Service Provider Report"
        isUserBadgeVisible
      >
        <Toolbar
          title="Service Provider Report"
          searchValue={search}
          handleSearchValueChange={handleSearchChange}
          hasSearch
          hasFilter
          filterOptions={nonArchiveStatuses}
          handleFilterOptionsChange={handleFilterOptionsChange}
          showAvatarOnPageDropdown
          hasPageSize
          pageSize={{
            id: pageSize,
            label: pageSize,
          }}
          handlePageSizeChange={handlePageSizeChange}
        />

        <div className={classes.tableContainer}>
          <Table
            idColumn="id"
            columns={columnDef}
            loading={!isFetched}
            data={data?.engagementUsers}
            handleSortChange={handleSortChange}
          />
        </div>

        <Pagination
          pageCount={data?.pageCount}
          handlePageChange={handlePageChange}
        />
      </HeaderMenuContainer>
    </div>
  );
};

export default ServiceProviderReportPage;
