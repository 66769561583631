import React from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_KEY, USER_ROLES } from '../constants/main';
import UiContextProvider from '../context/UiContext';
import PrivateRoute from '../containers/PrivateRoute';

import AdminLoginPage from '../pages/AdminLoginPage';
import LoginPage from '../pages/LoginPage';
import CreatePasswordPage from '../pages/CreatePasswordPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import PublicProfilePage from '../pages/PublicProfilePage';
import EngagementsPage from '../pages/EngagementsPage';
import EngagementPage from '../pages/EngagementPage';
import PaymentsPage from '../pages/PaymentsPage';
import DirtyPaymentLogsPage from '../pages/DirtyPaymentLogsPage';
import AccountSettingsPage from '../pages/AccountSettingsPage';
import ReferralsPage from '../pages/ReferralsPage';
import UsersManagementPage from '../pages/UsersManagementPage';
import CreateOrEditUserPage from '../pages/CreateOrEditUserPage';
import CreateEngagementPage from '../pages/CreateEngagementPage';
import ContactUsPage from '../pages/ContactUsPage';
import ServiceProviderProfilePage from '../pages/ServiceProviderProfilePage';
import GettingStartedPage from '../pages/GettingStartedPage';
import ServiceProviderReportPage from '../pages/ServiceProviderReportPage';
import ChatPage from '../pages/ChatPage';
import StripeUsers from '../pages/StripeUsers';

const stripePromise = loadStripe(STRIPE_KEY);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Routes = () => (
  <BrowserRouter>
    <Elements stripe={stripePromise}>
      <QueryClientProvider client={queryClient}>
        <UiContextProvider>
          <Switch>
            <Route exact path="/">
              <Redirect to="/engagements" />
            </Route>
            <Route exact path="/admin-login" component={AdminLoginPage} />
            <Route exact path="/login" component={LoginPage} />
            <Route
              exact
              path="/create-password"
              component={CreatePasswordPage}
            />
            <Route
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
            />
            <Route
              exact
              path="/public-profile/:userId/:linkId?"
              component={PublicProfilePage}
            />
            <PrivateRoute
              exact
              path="/getting-started"
              component={GettingStartedPage}
              roles={[
                USER_ROLES.ADMIN,
                USER_ROLES.PROJECT_MANAGER,
                USER_ROLES.CUSTOMER,
                USER_ROLES.SERVICE_PROVIDER,
              ]}
            />
            <PrivateRoute
              exact
              path="/engagements"
              component={EngagementsPage}
              roles={[
                USER_ROLES.ADMIN,
                USER_ROLES.PROJECT_MANAGER,
                USER_ROLES.CUSTOMER,
                USER_ROLES.SERVICE_PROVIDER,
              ]}
            />
            <PrivateRoute
              exact
              path="/engagements/create"
              component={CreateEngagementPage}
              roles={[USER_ROLES.ADMIN, USER_ROLES.PROJECT_MANAGER]}
            />
            <PrivateRoute
              exact
              path="/engagements/:id"
              component={EngagementPage}
              roles={[
                USER_ROLES.ADMIN,
                USER_ROLES.PROJECT_MANAGER,
                USER_ROLES.CUSTOMER,
              ]}
            />
            <PrivateRoute
              exact
              path="/billing-payments"
              component={PaymentsPage}
              roles={[USER_ROLES.CUSTOMER, USER_ROLES.SERVICE_PROVIDER]}
            />
            <PrivateRoute
              exact
              path="/dirty-payment-logs"
              component={DirtyPaymentLogsPage}
              roles={[USER_ROLES.ADMIN]}
            />
            <PrivateRoute
              exact
              path="/account-settings"
              component={AccountSettingsPage}
              roles={[
                USER_ROLES.ADMIN,
                USER_ROLES.PROJECT_MANAGER,
                USER_ROLES.CUSTOMER,
                USER_ROLES.SERVICE_PROVIDER,
              ]}
            />
            <PrivateRoute
              exact
              path="/referrals"
              component={ReferralsPage}
              roles={[USER_ROLES.ADMIN]}
            />
            <PrivateRoute
              exact
              path="/contact-us"
              component={ContactUsPage}
              roles={[
                USER_ROLES.ADMIN,
                USER_ROLES.PROJECT_MANAGER,
                USER_ROLES.CUSTOMER,
                USER_ROLES.SERVICE_PROVIDER,
              ]}
            />
            <PrivateRoute
              exact
              path="/service-provider-profile"
              component={ServiceProviderProfilePage}
              roles={[USER_ROLES.SERVICE_PROVIDER]}
            />
            <PrivateRoute
              exact
              path="/users-management"
              component={UsersManagementPage}
              roles={[USER_ROLES.ADMIN, USER_ROLES.PROJECT_MANAGER]}
            />
            <PrivateRoute
              exact
              path="/users-management/create"
              component={CreateOrEditUserPage}
              roles={[USER_ROLES.ADMIN, USER_ROLES.PROJECT_MANAGER]}
            />
            <PrivateRoute
              exact
              path="/users-management/:id/edit"
              component={CreateOrEditUserPage}
              roles={[USER_ROLES.ADMIN, USER_ROLES.PROJECT_MANAGER]}
            />
            <PrivateRoute
              exact
              path="/service-provider-report"
              component={ServiceProviderReportPage}
              roles={[USER_ROLES.ADMIN]}
            />
            <PrivateRoute
              exact
              path="/chat/:conversationId?"
              component={ChatPage}
              roles={[
                USER_ROLES.ADMIN,
                USER_ROLES.PROJECT_MANAGER,
                USER_ROLES.CUSTOMER,
                USER_ROLES.SERVICE_PROVIDER,
              ]}
            />
            <PrivateRoute
              exact
              path="/stripe-users"
              component={StripeUsers}
              roles={[USER_ROLES.ADMIN]}
            />
          </Switch>
        </UiContextProvider>
      </QueryClientProvider>
    </Elements>
  </BrowserRouter>
);

export default Routes;
