/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback } from 'react';

import cn from 'classnames';

import Input from '../../../../UI/Input';
import Avatar from '../../../../UI/Avatar';
import QuillTextEditor from '../../../../QuillTextEditor/QuillTextEditor';
import classes from './styles.module.scss';

const HiresOption = ({
  item,
  handleItemClick,
  isSelected,
  clientRate,
  serviceProviderRate,
  maxHours,
  responsibilities,
  handleClientRateChange,
  handleServiceProviderRateChange,
  handleMaxHoursChange,
  handleResponsibilitiesChange,
}) => {
  const handleItemSelect = useCallback(() => {
    if (!isSelected) {
      handleItemClick(item);
    }
  }, [item, isSelected, handleItemClick]);

  return (
    <li
      onClick={handleItemSelect}
      key={item.id}
      className={cn(classes.HiresOption, {
        [classes.selected]: isSelected,
      })}
    >
      <Avatar imagePath={item.avatarPath} alt={item.name} />
      <div className={classes.infoContainer}>
        <div className={classes.info}>
          <span className={classes.name}>{item.name}</span>
          {isSelected ? (
            <>
              <div className={cn(classes.inputContainer, classes.rate)}>
                <Input
                  value={clientRate}
                  onClick={(event) => event.stopPropagation()}
                  onChange={handleClientRateChange}
                  classnames={[classes.input]}
                  type="number"
                  placeholder="Client Rate"
                />
              </div>

              <div className={cn(classes.inputContainer, classes.rate)}>
                <Input
                  value={serviceProviderRate}
                  onChange={handleServiceProviderRateChange}
                  classnames={[classes.input]}
                  type="number"
                  placeholder="SP Rate"
                />
              </div>

              <div className={cn(classes.inputContainer)}>
                <Input
                  value={maxHours}
                  onClick={(event) => event.stopPropagation()}
                  onChange={handleMaxHoursChange}
                  classnames={[classes.input]}
                  type="number"
                  placeholder="Max Hours"
                />
              </div>
            </>
          ) : (
            <span className={classes.email}>{item.email}</span>
          )}
        </div>

        {isSelected && (
          <div className={classes.info}>
            <QuillTextEditor
              placeholder="Responsibilities..."
              contentContainerClassName={classes.textareaContainer}
              content={responsibilities}
              onClick={(event) => event.stopPropagation()}
              onChange={handleResponsibilitiesChange}
            />
          </div>
        )}
      </div>
    </li>
  );
};

export default HiresOption;
