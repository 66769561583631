import React from 'react';

import cn from 'classnames';

import classes from './styles.module.scss';

const VARIANTS = {
  OUTLINE: 'outline',
  TRANSPARENT: 'transparent',
};

const TYPE = {
  PRIMARY: classes.primary,
  SUCCESS: classes.success,
  WARNING: classes.warning,
  DANGER: classes.danger,
};

const GroupButtons = ({ buttonInfo, classnames = [] }) => {
  return (
    <div className={cn(classes.GroupButton, ...classnames)}>
      {buttonInfo.map((x, i) => {
        const { type, variant, content, ...rest } = x;
        return (
          <button
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={cn(TYPE[type], {
              [classes.outline]: variant === VARIANTS.OUTLINE,
              [classes.transparent]: variant === VARIANTS.TRANSPARENT,
            })}
            type="button"
            {...rest}
          >
            {content}
          </button>
        );
      })}
    </div>
  );
};

export default GroupButtons;
